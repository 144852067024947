import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from '../services/auth.service';
import { SharedSelectors } from '../state/shared.selectors';
import { AuthGuard } from './auth.guard';

@NgModule({
  declarations: [],
  imports: [],
  providers: [AuthGuard],
  exports: [],
})
export class GuardsModule {
}

export * from './auth.guard';

declare global {
  interface Window {
    __config: any;
  }
}

export const authInitializeGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthService).logIn(false, route.data?.clientId);
};

export const isLoadedGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const isLoaded = inject(Store).selectSnapshot<boolean>(SharedSelectors.isLoaded);
  const secondPath = state.url.indexOf('/', 1);
  return isLoaded ? true : inject(Router).parseUrl(secondPath !== -1 ? state.url.substring(0, secondPath) : '/');
};
