import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ModelError } from 'src/generated/apps-api';
import { SetErrors } from '../../state/shared.action';
import { SharedSelectors } from '../../state/shared.selectors';
import { LabelResolverContext } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss', './modal-container.phone.component.scss', './modal-container.tablet.component.scss']
})
export class ModalContainerComponent implements OnInit {
  constructor(
    public modal: NgbActiveModal,
    private store: Store
  ) {
  }

  @Input()
  public title: string;
  @Input()
  public showCloseButton = true;
  @Input()
  public showBackButton = false;
  @Input()
  public closeEmitsLastBack = false;
  @Input()
  public showStartAgainButtonOnError = false;
  @Output()
  public titleClick = new EventEmitter<void>();
  @Output()
  public backButtonClick = new EventEmitter<void>();
  @Output()
  public startAgainClick = new EventEmitter<void>();
  @Output()
  public closeClick = new EventEmitter<void>();

  public errors$: Observable<ModelError>;

  public labelResolverContext: LabelResolverContext;

  ngOnInit(): void {
    this.errors$ = this.store.select<ModelError>(SharedSelectors.errors);

    this.store.select(SharedSelectors.taxonomyVariablesContext)
      .pipe(untilDestroyed(this))
      .subscribe(variablesContext => {
        this.labelResolverContext = {
          parameters: variablesContext
        };
      });
  }

  clickTitle() {
    this.titleClick.emit();
  }

  clickStartAgain() {
    this.startAgainClick.emit();
  }

  close() {
    if (this.closeEmitsLastBack) {
      return this.closeClick.emit();
    }
    this.store.dispatch(new SetErrors());
    this.modal.dismiss('close');
  }
}
