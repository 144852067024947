import {Selector} from '@ngxs/store';
import {
  LegalEntity
} from '../../../generated/apps-api';
import {cloneDeep} from 'lodash-es';
import {PortalStateModel} from './portal.model';
import {PortalState} from './portal.state';

// required (https://github.com/ngxs/store/issues/541) otherwise when the state changes, all selectors
// will fire. The distinctUntilChanged won't work since we do a cloneDeep to fore the immutability
export class PortalSelectors {

  @Selector([PortalState.legalEntities])
  public static legalEntities(legalEntities: PortalStateModel['legalEntities']): LegalEntity[] {
    return cloneDeep(legalEntities);
  }

  @Selector([PortalState.userCompanyName])
  public static userCompanyName(userCompanyName: PortalStateModel['userCompanyName']): string {
    return userCompanyName;
  }
}
