import { Component, EventEmitter, Output } from '@angular/core';
import { ApiaxKeycloakToken } from 'src/app/portal/model/apiaxKeycloakToken.model';
import { UserService } from 'src/app/portal/services/user.service';
import { AppWindow } from 'src/config/AppConfig.model';

declare const window: AppWindow & Window;

@Component({
  selector: 'app-hamburger-profile',
  templateUrl: './hamburger-profile.component.html',
  styleUrls: ['./hamburger-submenu.component.scss', 'hamburger-submenu.tablet.component.scss'],
})
export class HamburgerProfileComponent {
  constructor(public userService: UserService) { }

  @Output() goBack: EventEmitter<MouseEvent> = new EventEmitter();
  public name: string;
  public companyName: string;
  public email: string;

  ngOnInit() {
    this.name = this.userService.name;
    this.companyName = this.userService.companyName;
    this.email = this.userService.email;
  }

  public handleLogout(event: MouseEvent) {
    event?.stopImmediatePropagation();
    return this.userService.logout();
  }



  public get photoUrl() {
    return this.userService.photoUrl;
  }

  public get keycloakToken(): ApiaxKeycloakToken {
    return this.userService?.keycloakToken;
  }

}
