<div class="wrapper">
  <div class="apps-setting-header">
      <span class="back-button" (click)="this.goBack.emit($event)">
          <i inlineSVG="assets/chevron_right.svg" ></i>
      </span>
      <span class="title">{{'settingsLabel' | toLabel}}</span>
  </div>
  <div class="input language" *ngIf="userService.hasLanguageChangePermission">
    <label class="input-label">{{'languagePreferenceLabel' | toLabel}}</label>
    <app-multiselect-dropdown
      id="PRF-AppLanguageSelection"
      data-hj-suppress
      [options]="preferredLanguageOptions"
      [showOptionTooltip]="false"
      [countrySelection]="true"
      [value]="preferredLanguage$ | async"
      [searchable]="false"
      placeholder="Select Preferred Language"
      (valueChanged)="handleChangePreferredLanguage($event)"
      class="options"
    ></app-multiselect-dropdown>
  </div>

  <div class="input">
    <label class="input-label">{{'legalEntityPreferenceLabel' | toLabel}}</label>
    <app-multiselect-dropdown
      id="PRF-LegalEntitySelection"
      data-hj-suppress
      [options]="legalEntityOptions"
      [showOptionTooltip]="false"
      [countrySelection]="true"
      [searchable]="false"
      [value]="preferredLegalEntity$ | async"
      placeholder="Select Legal Entity"
      (valueChanged)="handleChangeLegalEntity($event)"
      class="options"
    ></app-multiselect-dropdown>
  </div>

  <ng-container *ngIf="userService.isTesterUser">
      <div class="input test-mode">
      <label class="input-label">{{'testModeLabel' | toLabel}}</label>
      <app-toggle id="{{ isTestMode  ? 'PRF-TestModeOff' : 'PRF-TestModeOn' }}" [value]="isTestMode" (toggled)="toggle()"></app-toggle>
    </div>
  </ng-container>
</div>
