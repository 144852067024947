export interface Language {
  label: string;
  isoCode: string;
  countryCode: string;
}

export enum SupportedLanguages {
  UK = 'UK',
  DE = 'DE',
/*FR = 'FR',
  IT = 'IT',
  PT = 'PT',
  ES = 'ES'*/
}
