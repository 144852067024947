import { forOwn } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import uniqWith from 'lodash-es/uniqWith';
import { LabelResolverContext, LabelsResolverService } from 'src/app/shared/services/labelsResolver.service';
import { Products } from 'src/app/shared/state/shared.model';
import { JurisdictionResult, MarketingMaterialResults, Option } from 'src/generated/apps-api';
import { MarketingMaterialStateModel } from './marketing-material.model';

export const getOptionsFromResults = (results: Array<JurisdictionResult>, labelsResolverService: LabelsResolverService): { applicableCustomActionTagsOptions: Option[]; applicablePerformanceDataOptions: Option[] } => {
  const customActionsTags: Option[] = [];
  const performanceDataTags: Option[] = [];
  const customActionsTagsJurisdictions: {[key: string]: string[]} = {};
  const performanceDataTagsJurisdictions: {[key: string]: string[]} = {};


  for (const jurResult of results ?? []) {
    for (const leResult of jurResult.legalEntityResults ?? []) {
      for (const result of leResult.results ?? []) {
        for (const action of result.actions ?? []) {
          for (const tag of action.tags ?? []) {

            if (isDocumentTag(tag)) {
              let jurisdictions = customActionsTagsJurisdictions[tag?.toLowerCase()];
              if (!jurisdictions) {
                jurisdictions = [];
              }
              jurisdictions.push(jurResult.jurisdiction);
              customActionsTagsJurisdictions[tag?.toLowerCase()] = jurisdictions;
            }
            if (isPerformanceTag(tag)) {

              let jurisdictions = performanceDataTagsJurisdictions[tag?.toLowerCase()];
              if (!jurisdictions) {
                jurisdictions = [];
              }
              jurisdictions.push(jurResult.jurisdiction);
              performanceDataTagsJurisdictions[tag?.toLowerCase()] = jurisdictions;
            }
          }
        }
      }
    }
  }
  forOwn(customActionsTagsJurisdictions, (jurisdictions, key) => {
    const context: LabelResolverContext = {
      jurisdiction: jurisdictions,
      ruleSet: Products.MARKETING_MATERIAL_DOCUMENT_CHECK,
      field: 'title',
    };
    const name = labelsResolverService.resolveValue(key, context);
    customActionsTags.push({value: key, name});
  });

  forOwn(performanceDataTagsJurisdictions, (jurisdictions, key) => {
    const context: LabelResolverContext = {
      jurisdiction: jurisdictions,
      ruleSet: Products.MARKETING_MATERIAL_DOCUMENT_CHECK,
      field: 'title',
    };
    const name = labelsResolverService.resolveValue(key, context);
    performanceDataTags.push({value: key, name});
  });

  const applicableCustomActionTagsOptions: Option[] = uniqWith(customActionsTags, (value1, value2) => value1.value === value2.value);
  const applicablePerformanceDataOptions: Option[] = uniqWith(performanceDataTags, (value1, value2) => value1.value === value2.value);

  //Add none option
  const context: LabelResolverContext = {
    jurisdiction: 'GLOBAL',
    ruleSet: Products.MARKETING_MATERIAL_DOCUMENT_CHECK,
    field: 'title',
  };
  const name = labelsResolverService.resolveValue('mmAppStaticQuestionNoneOption', context);
  applicableCustomActionTagsOptions.push({ value: '[[mmAppStaticQuestionNoneOption]]', name });
  applicablePerformanceDataOptions.push({ value: '[[mmAppStaticQuestionNoneOption]]',name });

  return { applicableCustomActionTagsOptions, applicablePerformanceDataOptions };

};

/**
 * returns the mm results, filtered or not, and without tags so comparisons work
 *
 * @param state the state which contains the options selected and results
 * @returns mm results
 */
export const getFilteredResults = (state: MarketingMaterialStateModel): MarketingMaterialResults =>  {
  //Needs to be 2 since we are adding our none option, which is not directly a tag
  const singleCustomActionOption: string = state.applicableCustomActionTagsOptions?.length === 2 ? state.applicableCustomActionTagsOptions[0].value : undefined;
  const singlePerformanceDataOption: string = state.applicablePerformanceDataOptions?.length === 2 ? state.applicablePerformanceDataOptions[0].value : undefined;

  const allTags: string[] = [
    ...(singleCustomActionOption ? [singleCustomActionOption] : state.customActionsTagValues ?? []),
    ...(singlePerformanceDataOption ? [singlePerformanceDataOption] : state.performanceDataValues ?? [])
  ];
  const skipFiltering: boolean = allTags.length === 0;

  const results = cloneDeep(state.results);

  for (const jurResult of results?.jurisdictionResults ?? []) {
    for (const leResult of jurResult?.legalEntityResults ?? []) {
      for (const result of leResult?.results ?? []) {
        if (result.warningLabelId) continue;
        result.actions = result.actions?.filter(action => shouldIncludeResult(action?.tags, allTags, skipFiltering))
          .map(a => ({ ...a, tags: undefined })); //remove tags so we group without considering them
      }
      leResult.results = leResult.results?.filter(ler => ler.actions?.length || ler.warningLabelId);
    }
    jurResult.legalEntityResults = jurResult.legalEntityResults?.filter(jer => jer.results?.length);
  }

  return results;
};

const shouldIncludeResult = (tags: string[], allTags: string[], skipFiltering: boolean): boolean => {
  const hasTagOtherThanDocumentOrPerformance = tags?.some(tag => !isDocumentTag(tag) && !isPerformanceTag(tag));
  const isEmptyTags = !tags?.length;
  const hasSelectedTag = tags?.some(actionTag => allTags.some(tag => tag.toLowerCase() === actionTag?.toLowerCase()));

  return skipFiltering || isEmptyTags || hasTagOtherThanDocumentOrPerformance || hasSelectedTag;
};

const isDocumentTag = (tag: string): boolean => {
  return tag.toLowerCase().startsWith('[[document') || tag.toLowerCase().startsWith('document');
};

const isPerformanceTag = (tag: string): boolean => {
  return tag.toLowerCase().startsWith('[[performance') || tag.toLowerCase().startsWith('performance');
};
