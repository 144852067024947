/**
 * Apps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.61.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Variable { 
    identifier?: string;
    type?: Variable.TypeEnum;
}
export namespace Variable {
    export type TypeEnum = 'NO_OPTIONS_REQUIRED' | 'LEGAL_ENTITY' | 'INSTRUMENT';
    export const TypeEnum = {
        NO_OPTIONS_REQUIRED: 'NO_OPTIONS_REQUIRED' as TypeEnum,
        LEGAL_ENTITY: 'LEGAL_ENTITY' as TypeEnum,
        INSTRUMENT: 'INSTRUMENT' as TypeEnum
    };
}


