import { LegalEntityMode, MarketingMaterialStateModel } from './marketing-material.model';

export const DEFAULT_STATE: MarketingMaterialStateModel = {
  distributionCountries: undefined,
  allCountries: undefined,
  selectedDistributionCountries: undefined,
  selectedDistributionCountriesWithResults: undefined,
  selectedLegalEntitiesIdsWithResults: undefined,
  legalEntities: undefined,
  attributes: undefined,
  ruleSetsInfo: undefined,
  questions: undefined,
  results: undefined,
  labelsWithVariables: undefined,
  applicableCustomActionTagsOptions: undefined,
  applicablePerformanceDataOptions: undefined,
  customActionsTagValues: undefined,
  performanceDataValues: undefined,
  disclaimerLanguages: {
    supportedDisclaimerLanguages: [],
    selectedDisclaimerLanguages: []
  },
  instruments: undefined,
  isSearchInstrumentEnable: false,
  selectedLegalEntitiesMode: LegalEntityMode.PER_COUNTRY,
  generatedOn: undefined,
  generatedByFullName: undefined
};
