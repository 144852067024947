import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { cloneDeep, isEqual, range, sortBy } from 'lodash-es';
import { JumpedSteps, UpdateTestMode } from 'src/app/shared/state/shared.action';
import { RESULT_TYPES } from 'src/app/shared/state/shared.model';
import {
  CrossBorderAction,
  CrossBorderActionProperty,
  CrossBorderAttribute,
  CrossBorderEvaluationInformation,
  CrossBorderProduct,
  CrossBorderQuestion,
  PortfolioEntry,
  RuleSetInfo
} from 'src/generated/apps-api';
import { SaveCBAudit } from './cross-border-audit.action';
import {
  AddInstrumentsQuestion,
  BackToResults,
  InstrumentsChangedOrAdded,
  RemoveInstrumentsResults,
  SetInstrumentsAttribute,
  SetInstrumentsAttributes,
  SetInstrumentsProducts,
  SetInstrumentsQuestions,
  SetInstrumentsResults,
  SetInstrumentsStepNumber,
  SetPortfolio,
  ShowOverlay,
  UpdateInstrumentsCtas,
  UsePortfolio
} from './cross-border-instruments.action';
import { CrossBorderInstrumentsStateModel } from './cross-border-instruments.model';
import { SetResults, SetScenario, SetSelectedProducts, StartAgain } from './cross-border.action';
import { ExtendedProduct } from './cross-border.model';

const DEFAULT_STATE: CrossBorderInstrumentsStateModel = {
  attributes: [],
  questions: [],
  currentStep: 0,
  instrumentsRuleSets: [],
  showOverlay: false,
  usePortfolio: false,
  results: undefined,
  instrumentsProducts: []
};

export const FUNDS_ACTIVITIES = ['provideAdviceInstruments', 'receiveExecutionCustody', 'buyWithinMandateAM', 'distributeFinancialInstruments', 'financialResearch'];

@State<CrossBorderInstrumentsStateModel>({
  name: 'crossBorderInstruments',
  defaults: DEFAULT_STATE
})
@Injectable()
export class CrossBorderInstrumentsState {
  constructor(private store: Store) {}

  @Selector()
  public static fullState(state: CrossBorderInstrumentsStateModel): CrossBorderInstrumentsStateModel {
    return state;
  }

  @Selector()
  public static fundsFlowHasRun(state: CrossBorderInstrumentsStateModel): boolean {
    return !!state?.results || !!state?.questions?.length;
  }

  @Selector()
  public static questions(state: CrossBorderInstrumentsStateModel): CrossBorderQuestion[] {
    return state?.questions;
  }

  @Selector()
  public static instrumentsRuleSets(state: CrossBorderInstrumentsStateModel): RuleSetInfo.RuleSetTypeEnum[] {
    return state?.instrumentsRuleSets;
  }

  @Selector()
  public static instrumentsProductsLabels(state: CrossBorderInstrumentsStateModel): CrossBorderProduct[] {
    return state?.evaluationInformation.evaluatedRules.map(rule => ({ id: rule.ruleSetId, name: rule.ruleSetLabelId }));
  }

  @Selector()
  public static instrumentsProducts(state: CrossBorderInstrumentsStateModel): ExtendedProduct[] {
    return state?.instrumentsProducts;
  }

  @Selector()
  public static attributes(state: CrossBorderInstrumentsStateModel): CrossBorderAttribute[] {
    return state?.attributes;
  }

  @Selector()
  public static results(state: CrossBorderInstrumentsStateModel): { [key: string]: CrossBorderAction[] } {
    return state.results;
  }

  @Selector()
  public static currentStepNumber(state: CrossBorderInstrumentsStateModel): number {
    return state.currentStep;
  }

  @Selector()
  public static evaluationInformation(state: CrossBorderInstrumentsStateModel): CrossBorderEvaluationInformation {
    return state.evaluationInformation;
  }

  @Selector()
  public static showOverlay(state: CrossBorderInstrumentsStateModel): boolean {
    return state.showOverlay;
  }

  @Selector()
  public static usePortfolio(state: CrossBorderInstrumentsStateModel): boolean {
    return state.usePortfolio;
  }

  @Selector()
  public static portfolioEntries(state: CrossBorderInstrumentsStateModel): PortfolioEntry[] {
    return state.portfolioEntries;
  }

  @Action(AddInstrumentsQuestion)
  public addQuestion(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: AddInstrumentsQuestion) {
    const evaluationInformation = payload.evaluationInformation;
    const questions = cloneDeep(context.getState().questions);
    questions.push(payload.question);

    return context.patchState({ results: undefined, questions, evaluationInformation });
  }

  @Action(SetInstrumentsQuestions)
  public setQuestions(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: SetInstrumentsQuestions) {
    return context.patchState({ questions: payload.questions });
  }

  @Action(SetInstrumentsResults)
  public setInstrumentsResults(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: SetInstrumentsResults) {
    if (payload === undefined) {
      return context.setState(DEFAULT_STATE);
    }

    const { activities, evaluationInformation, questions, attributes, portfolioEntries, usePortfolio, showOverlay, instrumentsProducts } = payload;
    this.cleanupEmptyResults(activities);
    const state = context.getState();
    return context.patchState({
      results: activities,
      evaluationInformation,
      questions: questions ?? state.questions,
      attributes: attributes ?? state.attributes,
      portfolioEntries: portfolioEntries ?? state.portfolioEntries,
      showOverlay: showOverlay ?? state.showOverlay,
      usePortfolio: usePortfolio ?? state.usePortfolio,
      instrumentsProducts: instrumentsProducts ?? state.instrumentsProducts
    });
  }

  private cleanupEmptyResults(activities: { [key: string]: CrossBorderAction[] }) {
    for (const property in activities) {
      if (activities.hasOwnProperty(property)) {
        activities[property] = activities[property].filter(a => a.properties.length !== 0);
      }
    }
  }

  @Action(SetInstrumentsStepNumber)
  public setStepNumber(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: SetInstrumentsStepNumber) {
    const previousStep = CrossBorderInstrumentsState.currentStepNumber(context.getState());
    const currentStep = payload.stepNumber;
    context.patchState({ currentStep });
    if (currentStep > previousStep + 1) {
      const jumpedSteps = range(previousStep + 1, currentStep);
      context.dispatch(new JumpedSteps({ jumpedSteps }));
    }

    return context.patchState({ currentStep: payload.stepNumber });
  }

  @Action(SetInstrumentsAttribute)
  public setInstrumentsAttribute(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: SetInstrumentsAttribute) {
    const newAttribute = payload.newAttribute;
    let attributes = context.getState().attributes;
    let questions = context.getState().questions;

    const attributeIndex = attributes.findIndex(a => a.name === newAttribute.name && isEqual(sortBy(newAttribute.ruleSets), sortBy(a.ruleSets)));
    attributes = attributes.filter((_a, i) => attributeIndex === -1 || i < attributeIndex).concat(newAttribute);

    const questionIndex = questions.findIndex(q => q.property === newAttribute.name && isEqual(sortBy(newAttribute.ruleSets), sortBy(q.products.map(p => p.ruleset))));
    questions = questions.filter((_a, i) => questionIndex === -1 || i <= questionIndex);

    return context.patchState({ attributes, questions });
  }

  @Action(SetInstrumentsAttributes)
  public setInstrumentsAttributes(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: SetInstrumentsAttributes) {
    return context.patchState({ attributes: payload.attributes });
  }

  @Action([StartAgain, SetSelectedProducts, UpdateTestMode])
  public startAgain(context: StateContext<CrossBorderInstrumentsStateModel>) {
    const { instrumentsProducts, portfolioEntries } = context.getState();
    return context.setState({ ...DEFAULT_STATE, portfolioEntries, instrumentsProducts });
  }

  @Action([BackToResults, RemoveInstrumentsResults])
  public backToResults(context: StateContext<CrossBorderInstrumentsStateModel>) {
    const { instrumentsRuleSets, portfolioEntries, instrumentsProducts } = context.getState();
    return context.setState({ ...DEFAULT_STATE, instrumentsRuleSets, portfolioEntries, instrumentsProducts });
  }

  @Action(ShowOverlay)
  public showOverlay(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: ShowOverlay) {
    const showOverlay = payload.showOverlay;
    return context.patchState({ showOverlay });
  }

  @Action(UsePortfolio)
  public usePortfolio(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: UsePortfolio) {
    const usePortfolio = payload.usePortfolio;
    return context.patchState({ usePortfolio });
  }

  @Action([SetResults, SetScenario, UpdateInstrumentsCtas])
  public setInstrumentRuleSetsFromResults(context: StateContext<CrossBorderInstrumentsStateModel>, action: SetResults | SetScenario | UpdateInstrumentsCtas) {
    const payloadResults = (action as SetResults).payload.results || (action as SetScenario).payload.scenario.results || (action as UpdateInstrumentsCtas).payload.results;
    const isFirstScenario = (action as SetScenario).payload.scenarioIndex === 0;
    if (!payloadResults || !isFirstScenario) {
      return context.getState();
    }
    const instrumentsRuleSets = Object.entries(payloadResults).reduce<RuleSetInfo.RuleSetTypeEnum[]>((products, result) => {
      const allProperties = result[1].activities.reduce<CrossBorderActionProperty[]>((props, act) => props.concat(...act.properties), []);

      const hasActivity = allProperties.some(p => FUNDS_ACTIVITIES.indexOf(p.titleLabelId) > -1 && (p.proActive?.value === RESULT_TYPES.YES || p.reverse?.value === RESULT_TYPES.YES));

      const ruleset: RuleSetInfo.RuleSetTypeEnum = result[0] as RuleSetInfo.RuleSetTypeEnum;
      return hasActivity ? products.concat(ruleset) : products;
    }, []);

    if (instrumentsRuleSets.length === 0) {
      const { portfolioEntries, instrumentsProducts } = context.getState();
      return context.setState({ ...DEFAULT_STATE, portfolioEntries, instrumentsProducts });
    }

    /* delete the results only for the services that no longer have instruments available */
    const results = cloneDeep(context.getState().results);
    if (CrossBorderInstrumentsState.fundsFlowHasRun(context.getState())) {
      for (const product in results) {
        if (instrumentsRuleSets.indexOf(product as RuleSetInfo.RuleSetTypeEnum) === -1) delete results[product];
      }
    }
    return context.patchState({ instrumentsRuleSets, results });
  }

  @Action(SetPortfolio)
  public setPortfolio(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: SetPortfolio) {
    const portfolioEntries = payload.portfolioEntries.sort((e1, e2) => e1.productType?.localeCompare(e2.productType));
    return context.patchState({ portfolioEntries });
  }

  @Action(SetInstrumentsProducts)
  public setInstrumentsProducts(context: StateContext<CrossBorderInstrumentsStateModel>, { payload }: SetInstrumentsProducts) {
    const instrumentsProducts: ExtendedProduct[] = payload.products as ExtendedProduct[];
    return context.patchState({ instrumentsProducts });
  }

  @Action(InstrumentsChangedOrAdded)
  public instrumentsAddedOrChanged() {
    return this.store.dispatch(
      new SaveCBAudit({
        generatedOn: null,
        metadata: { instrumentsFlow: true }
      })
    );
  }
}
