import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { InlineSVGModule } from 'ng-inline-svg';

import { PipeModule } from '../pipes/pipe-module.module';
import { AccordionComponent } from './accordion/accordion.component';
import { BackButtonComponent } from './button/back-button/back-button.component';
import { ButtonComponent } from './button/button.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ErrorComponent } from './error/error.component';
import { FlagComponent } from './flag/flag.component';
import { FooterComponent } from './footer/footer.component';
import { HamburgerComponent } from './hamburger-menu/hamburger-menu.component';
import { HamburgerProfileComponent } from './hamburger-menu/hamburger-profile.component';
import { HamburgerSettingsComponent } from './hamburger-menu/hamburger-settings.component';
import { HeaderComponent } from './header/header.component';
import { HoverTooltipComponent } from './hover-tooltip/hover-tooltip.component';
import { LabelLinkClickListenerDirective } from './labels-resolver/click-listener/label-link-click-listener.directive';
import { LabelsResolverModalComponent } from './labels-resolver/modal/labels-resolver-modal.component';
import { LoadingSkeletonComponent } from './loading-skeleton/loading-skeleton.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { MultiselectCheckboxesComponent } from './multiselect-checkboxes/multiselect-checkboxes.component';
import { MultiselectDropdownComponent } from './multiselect-dropdown/multiselect-dropdown.component';
import { JumpingStepsComponent } from './notification-bar/jumping-steps.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { QuestionDescriptionComponent } from './question-description/question-description.component';
import { QuestionComponent } from './question/question.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { ExportButtonComponent } from './results/ctas/export-button/export-button.component';
import { StartAgainButtonComponent } from './results/ctas/start-again-button/start-again-button.component';
import { ResultsContainerComponent } from './results/results-container.component';
import { ResultsExportModalComponent } from './results/results-export-modal/results-export-modal.component';
import { ResultsPasswordModalComponent } from './results/results-password-modal/results-password-modal.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ShowTermsComponent } from './show-terms/show-terms.component';
import { ShowWhiteLabelComponent } from './show-white-label/show-white-label.component';
import { SimpleDropdownSelectComponent } from './simple-dropdown-select/simple-dropdown-select.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SupportLinkComponent } from './support-link/support-link.component';
import { TextInputComponent } from './text-input/text-input.component';
import { ToggleComponent } from './toggle/toggle.component';
import { ShowTooltipModalDirective } from './tooltip/show-tooltip-modal.directive';
import { TooltipComponent } from './tooltip/tooltip.component';
import { CompanyWhiteLabelSelectorComponent } from './company-white-label-selector/company-white-label-selector.component';

@NgModule({
  declarations: [
    ButtonComponent,
    ErrorComponent,
    QuestionComponent,
    QuestionDescriptionComponent,
    TooltipComponent,
    RadioButtonComponent,
    MultiselectDropdownComponent,
    NotificationBarComponent,
    JumpingStepsComponent,
    HamburgerComponent,
    HamburgerProfileComponent,
    HamburgerSettingsComponent,
    HeaderComponent,
    LoadingSkeletonComponent,
    LabelsResolverModalComponent,
    LabelLinkClickListenerDirective,
    ShowTooltipModalDirective,
    SpinnerComponent,
    TextInputComponent,
    NumberInputComponent,
    MainHeaderComponent,
    ModalContainerComponent,
    ResultsPasswordModalComponent,
    ResultsContainerComponent,
    ResultsExportModalComponent,
    MultiselectCheckboxesComponent,
    AccordionComponent,
    SimpleDropdownSelectComponent,
    ExportButtonComponent,
    DisclaimerComponent,
    FlagComponent,
    FooterComponent,
    ToggleComponent,
    SearchBarComponent,
    BackButtonComponent,
    StartAgainButtonComponent,
    SupportLinkComponent,
    ShowTermsComponent,
    HoverTooltipComponent,
    ShowWhiteLabelComponent,
    CompanyWhiteLabelSelectorComponent
  ],
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, PipeModule, InlineSVGModule],
  exports: [
    ButtonComponent,
    ErrorComponent,
    LoadingSkeletonComponent,
    QuestionComponent,
    QuestionDescriptionComponent,
    NotificationBarComponent,
    JumpingStepsComponent,
    TooltipComponent,
    HamburgerComponent,
    HeaderComponent,
    LabelsResolverModalComponent,
    MainHeaderComponent,
    ShowTooltipModalDirective,
    SpinnerComponent,
    TextInputComponent,
    NumberInputComponent,
    RadioButtonComponent,
    ModalContainerComponent,
    ResultsContainerComponent,
    MultiselectCheckboxesComponent,
    AccordionComponent,
    SimpleDropdownSelectComponent,
    ExportButtonComponent,
    LabelLinkClickListenerDirective,
    DisclaimerComponent,
    FlagComponent,
    FooterComponent,
    ToggleComponent,
    SearchBarComponent,
    BackButtonComponent,
    StartAgainButtonComponent,
    MultiselectDropdownComponent,
    SupportLinkComponent,
    ShowTermsComponent,
    ShowWhiteLabelComponent,
    CompanyWhiteLabelSelectorComponent,
    HoverTooltipComponent
  ],
})
export class ComponentsModule {}
