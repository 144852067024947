import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {LabelResolverContext} from '../../services/labelsResolver.service';

@Component({
  selector: 'app-hover-tooltip',
  templateUrl: './hover-tooltip.component.html',
  styleUrls: ['./hover-tooltip.component.scss'],
})
export class HoverTooltipComponent {

  @Input() helpText = '';
  @Input() darkTheme = false;

  public isHidden = true;
  public withEllipsis = false;
  public context = {};

  @ViewChild('tooltipRef')
  private tooltipRef: ElementRef;

  public showTooltipAtPosition(top: number, left: number, helpText: string, context: LabelResolverContext) {
    this.context = context;
    this.helpText = helpText;
    setTimeout(() => {
      if (!this.tooltipRef) {
        return;
      }
      this.tooltipRef.nativeElement.style.top = top + 'px';
      this.tooltipRef.nativeElement.style.left = left + 'px';
      this.isHidden = false;
      const tooltipPosition = this.tooltipRef.nativeElement.getBoundingClientRect();
      const tooltipChildPosition = this.tooltipRef.nativeElement.lastElementChild?.getBoundingClientRect();
      const heightDifference = tooltipPosition.height - tooltipChildPosition.height;
      this.withEllipsis = tooltipChildPosition?.bottom - heightDifference / 2 > tooltipPosition.bottom;
    }, 10);
  }

  public hideTooltip() {
    this.isHidden = true;
  }

  get themeClass() {
    return (this.darkTheme ? 'theme-dark' : 'theme-light');
  }
}
