import { RuleSet } from 'src/app/shared/state/shared.model';
import { ClientOnboardingStateModel } from './client-onboarding.model';

export const DEFAULT_STATE: ClientOnboardingStateModel = {
  availableJurisdictions: [],
  jurisdiction: undefined,
  ruleSetContexts: {},
  requirementGroups: [],
  roles: undefined,
  currentRuleSet: RuleSet.CLIENT_CLASSIFICATION,
  expandedRoles: {},
};
