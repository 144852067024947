import { CountryOverview, CrossBorderAction, CrossBorderActivitiesAndDocuments, CrossBorderContext, CrossBorderEvaluationInformation, CrossBorderProduct, PortfolioEntry } from 'src/generated/apps-api';
import { CrossBorderAttribute } from 'src/generated/apps-api/model/crossBorderAttribute';
import { CrossBorderQuestion, ExtendedProduct, JurisdictionCountryOverviews, Scenario } from './cross-border.model';

export enum CrossborderAction {
  LOAD_CONTEXT = '(Cross-border) [Load Context] Load application context',
  ADD_QUESTION = '(Cross-border) [Evaluation] Add question to scenario',
  SET_STEP_NUMBER = '(Cross-border) [Evaluation] Set Step Number',
  SET_SELECTED_PRODUCTS = '(Cross-border) [Evaluation] Set selected products',
  SET_ISCROSSBORDERIB = '(Cross-border) [Load Context] Set isCrossBorderIB',
  SET_ATTRIBUTE = '(Cross-border) [Evaluation] Set attribute to scenario',
  SET_RESULTS = '(Cross-border) [Evaluation] Set results to scenario',
  SET_SCENARIO = '(Cross-border) [Evaluation] Set scenario',
  DELETE_SCENARIO = '(Cross-border) [Evaluation] Delete scenario',
  SET_SELECTED_SERVICE = '(Cross-border) [Set Selected Service] Change selected service',
  SET_COUNTRY_OVERVIEW = '(Cross-border) [Set Country Overview] Set the country overview for a given jurisdiction and family',
  START_AGAIN = '(Cross-border) [Start Again] Resets the apps state',
  SET_STATIC_RESULT = '(Cross Border) [Set Static Result] Sets the static result state',
  SET_SUPERLOGIC = '(Cross Border) [Set Superlogic] Sets superlogic',
  TOGGLE_MKTG_ORIGINATION = '(Cross Border) [Toggle Marketing Origination] Toggle Marketing Origination',
  SET_PHONE_SELECTED_SCENARIO = '(Cross-border) [Set Selected Scenario] Change selected scenario index - ONLY MOBILE',
  SET_SAVED_CONTEXTS = '(Cross-border) [Save Context] Set Saved Context list',
  SET_SAVED_CONTEXT = '(Cross-border) [Save Context] Set Saved Context',
  DELETE_SAVED_CONTEXT = '(Cross-border) [Save Context] Delete Saved Context',
  REMOVE_SAVED_CONTEXT = '(Cross-border) [Save Context] Remove Saved Context',
  REMOVE_EXTRA_SCENARIOS = '(Cross-border) [Save Context] Remove Extra Scenarios',
  SCENARIO_CHANGED = '(Cross-border) [Audit BAM data] Scenario changed'
}

export class LoadContext {
  public static readonly type = CrossborderAction.LOAD_CONTEXT;

  constructor() { }
}

export class AddQuestion {
  public static readonly type = CrossborderAction.ADD_QUESTION;

  constructor(public payload?: { scenarioIndex?: number; question: CrossBorderQuestion }) { }
}

export class SetAttribute {
  public static readonly type = CrossborderAction.SET_ATTRIBUTE;

  constructor(public payload?: { scenarioIndex?: number; newAttribute: CrossBorderAttribute }) { }
}

export class SetSavedContexts {
  public static readonly type = CrossborderAction.SET_SAVED_CONTEXTS;

  constructor(public payload?: {savedContexts: CrossBorderContext[] }) { }
}

export class DeleteSavedContext {
  public static readonly type = CrossborderAction.DELETE_SAVED_CONTEXT;

  constructor(public payload?: {id: string}) { }
}

export class SetResults {
  public static readonly type = CrossborderAction.SET_RESULTS;

  constructor(
    public payload?: {
      results: { [key: string]: CrossBorderActivitiesAndDocuments };
      evaluationInformation?: CrossBorderEvaluationInformation;
      scenarioIndex?: number;
      jurisdiction: string;
    }
  ) { }
}

export class SetScenario {
  public static readonly type = CrossborderAction.SET_SCENARIO;

  constructor(
    public payload?: {
      scenario: Scenario;
      scenarioIndex?: number;
      jurisdiction?: string;
      generatedOn?: string;
      generatedByFullName?: string;
    }
  ) { }
}

export class DeleteScenario {
  public static readonly type = CrossborderAction.DELETE_SCENARIO;

  constructor(
    public payload?: {
      scenarioIndex?: number;
    }
  ) { }
}

export class StartAgain {
  public static readonly type = CrossborderAction.START_AGAIN;

  constructor() { }
}

export class SetStaticResult {
  public static readonly type = CrossborderAction.SET_STATIC_RESULT;

  constructor(public payload?: SetStaticResultPayload) { }
}
export interface SetStaticResultPayload {
  firstScenarioJurisdiction: string;
  state: Scenario[];
  products: ExtendedProduct[];
  countryOverviews: JurisdictionCountryOverviews;
  selectedService: CrossBorderProduct;
  isCrossBorderIB: boolean;

  //Instruments
  instrumentsQuestions: CrossBorderQuestion[];
  instrumentsAttributes: CrossBorderAttribute[];
  instrumentsActivities: { [key: string]: CrossBorderAction[] };
  instrumentsEvaluationInformation: CrossBorderEvaluationInformation[];
  instrumentsProducts: ExtendedProduct[];
  portfolioEntries: PortfolioEntry[];
  usePortfolio: boolean;
  showOverlay: boolean;
}

export class SetStepNumber {
  public static readonly type = CrossborderAction.SET_STEP_NUMBER;

  constructor(public payload?: { stepNumber: number }) { }
}

export class SetSelectedProducts {
  public static readonly type = CrossborderAction.SET_SELECTED_PRODUCTS;

  constructor(public payload?: { products: CrossBorderProduct[] }) { }
}

export class SetIsCrossBorderIB {
  public static readonly type = CrossborderAction.SET_ISCROSSBORDERIB;
  constructor(public payload?: boolean) { }
}

export class SetCountryOverview {
  public static readonly type = CrossborderAction.SET_COUNTRY_OVERVIEW;

  constructor(public payload?: { jurisdiction: string; family: string; countryOverview: CountryOverview }) { }
}

export class SetSuperlogic {
  public static readonly type = CrossborderAction.SET_SUPERLOGIC;

  constructor(public payload?: boolean) { }
}

export class SetSelectedService {
  public static readonly type = CrossborderAction.SET_SELECTED_SERVICE;

  constructor(public payload?: CrossBorderProduct) { }
}

export class ToggleMarketingOrigination {
  public static readonly type = CrossborderAction.TOGGLE_MKTG_ORIGINATION;

  constructor(public payload?: boolean) { }
}
export class SetPhoneSelectedScenario {
  public static readonly type = CrossborderAction.SET_PHONE_SELECTED_SCENARIO;

  constructor(public payload?: number) { }
}
//Saved Context

export class SetSavedContext {
  public static readonly type = CrossborderAction.SET_SAVED_CONTEXT;

  constructor(public payload?: {id?: string; name?: string; isSavedContext?: boolean} ) { }
}

export class RemoveSavedContext {
  public static readonly type = CrossborderAction.REMOVE_SAVED_CONTEXT;

  constructor() { }
}

export class RemoveExtraScenarios {
  public static readonly type = CrossborderAction.REMOVE_EXTRA_SCENARIOS;

  constructor() { }
}

// audit bam data

export class ScenarioChanged {
  public static readonly type = CrossborderAction.SCENARIO_CHANGED;

  constructor(public scenarioIndex: number) {}
}

