import { CrossBorderQuestion, Question } from 'src/generated/apps-api';

export const REPRESENTATIVE_OF_LEGAL_ENTITY = 'representativeOfALegalEntity';

export const INVESTOR_TYPE_QUESTION: CrossBorderQuestion = {
  property: 'investorTypeAppsInvestorTypeSelection',
  questionType: Question.QuestionTypeEnum.OTHER,
  titleLabelId: 'investorTypeAppsInvestorTypeSelection',
  products:[],
  options: [
    {
      nameLabelId: 'legalEntityCrossborderApp',
      value: 'legalEntity',
    },
    {
      nameLabelId: 'naturalPersonsCrossborderApp',
      value: 'naturalPersons',
    },
    {
      nameLabelId: 'representativeOfALegalEntityCrossborderApp',
      value: REPRESENTATIVE_OF_LEGAL_ENTITY,
    },
  ],
};

export const TYPE_OF_REPRESENTATIVE_QUESTION: CrossBorderQuestion = {
  property: 'typeOfRepresentativeAppsInvestorTypeSelection',
  questionType: Question.QuestionTypeEnum.OTHER,
  titleLabelId: 'typeOfRepresentativeAppsInvestorTypeSelection',
  products:[],
  options: [
    {
      nameLabelId: 'beneficialOwnerCrossborderApp',
      value: 'BeneficialOwner',
    },
    {
      nameLabelId: 'powerOfAttorneyCrossborderApp',
      value: 'PowerOfAttorney',
    },
    {
      nameLabelId: 'otherAppLabel',
      value: 'other',
    },
  ],
};

/**Superlogic only**/
export const REPRESENTATIVE_PHYSICAL_LOCATION_QUESTION: CrossBorderQuestion = {
  property: 'presentProspectClientEventCountryRepresentativeInvestorTypeSelectionApp',
  questionType: Question.QuestionTypeEnum.COUNTRY,
  titleLabelId: 'presentProspectClientEventCountryRepresentativeInvestorTypeSelectionApp',
  products:[]
};

export const REPRESENTATIVE_DOMICILE_QUESTION: CrossBorderQuestion = {
  property: 'clientDomicileRepresentativeInvestorTypeSelectionApp',
  questionType: Question.QuestionTypeEnum.COUNTRY,
  titleLabelId: 'clientDomicileRepresentativeInvestorTypeSelectionApp',
  products:[]
};

export const CLIENT_DOMICILE_QUESTION: CrossBorderQuestion = {
  property: 'clientDomicile',
  questionType: Question.QuestionTypeEnum.COUNTRY,
  titleLabelId: 'clientDomicileCrossborderApp',
  products:[]
};

//only if no superlogic or superlogic and no representative
export const PRESENT_CLIENT_COUNTRY_QUESTION: CrossBorderQuestion = {
  property: 'presentProspectClientEventCountry',
  questionType: Question.QuestionTypeEnum.COUNTRY,
  titleLabelId: 'presentClientCountryCrossborderApp',
  jurisdiction: undefined,
  products:[]
};

export const PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION: CrossBorderQuestion = {
  property: 'presentFIEmployeeCountry',
  questionType: Question.QuestionTypeEnum.COUNTRY,
  titleLabelId: 'presentFiEmployeeCountryCrossborderApp',
  jurisdiction: undefined,
  products:[]
};

export const CROSS_BORDER_STATIC_QUESTIONS: CrossBorderQuestion[] = [
  INVESTOR_TYPE_QUESTION,
  TYPE_OF_REPRESENTATIVE_QUESTION,
  REPRESENTATIVE_PHYSICAL_LOCATION_QUESTION,
  REPRESENTATIVE_DOMICILE_QUESTION,
  CLIENT_DOMICILE_QUESTION,
  PRESENT_CLIENT_COUNTRY_QUESTION,
  PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION,
];
