import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '../shared/components/components.module';
import { PipeModule } from '../shared/pipes/pipe-module.module';
import { AppsErrorsRoutingModule } from './apps-errors-routing.module';
import { ErrorInterceptor } from './interceptors/error.interceptor';

@NgModule({
  imports: [CommonModule, AppsErrorsRoutingModule, PipeModule, ComponentsModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
})
export class ErrorsModule {}
