/**
 * Apps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.61.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { Question } from './question';
import { RequirementGroup } from './requirementGroup';
import { RuleSetInfo } from './ruleSetInfo';


export interface ClientClassificationResponse { 
    question?: Question;
    roles?: Array<Role>;
    nextRuleSet?: ClientClassificationResponse.NextRuleSetEnum;
    requirementsGroup?: Array<RequirementGroup>;
    ruleSetInfo?: RuleSetInfo;
}
export namespace ClientClassificationResponse {
    export type NextRuleSetEnum = 'CLIENT_CLASSIFICATION' | 'CLIENT_IDENTIFICATION_NATURAL_PERSON' | 'CLIENT_IDENTIFICATION_NON_NATURAL_PERSON' | 'OPERATIONAL_ACTIVITY' | 'TRANSFER_TERRITORIAL_SCOPING' | 'DATA_CLASSIFICATION' | 'DATA_TRANSFER_CONDITION' | 'DATA_TRANSFER' | 'SPECIAL_CONTEXT_DATA_TRANSFER' | 'BRAND_MARKETING' | 'CUSTODY_AND_PAYMENT' | 'DISCRIMINATORY_ASSET_MANAGEMENT' | 'INVESTMENT_ADVICE' | 'LOANS_AND_CREDITS' | 'RESEARCH' | 'SOCIAL_CONTACTS' | 'WEBSITE' | 'DISTRIBUTION' | 'WEALTH_PLANNING' | 'WEALTH_MANAGEMENT_SERVICES' | 'E_BANKING_SERVICES' | 'TRUST_FICUCIARY_SERVICES' | 'INTERMEDIARIES' | 'CLIENT_ADMINISTRATION_AND_DOCUMENTS' | 'DISTRIBUTION_STRUCTURED_PRODUCTS' | 'COUNTRY_OVERVIEW_FINANCIAL_SERVICES' | 'COUNTRY_OVERVIEW_FINANCIAL_INSTRUMENTS' | 'BRAND_MARKETING_IB' | 'WEBSITE_IB' | 'CAPITAL_MARKET_TRANSACTION_SERVICE_IB' | 'FOREIGN_EXCHANGE_SERVICE_IB' | 'TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB' | 'TREASURY_CASH_BANKNOTE_SERVICES_IB' | 'PRECIOUS_METAL_SERVICES_IB' | 'RATES_SERVICES_IB' | 'ADVISORY_FOR_MERGERS_ACQUISITIONS_IB' | 'DIGITAL_APPLICATIONS_SOLUTIONS_IB' | 'CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB' | 'BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB' | 'TRADE_FINANCE_IB' | 'CORRESPONDANCE_BANKING_IB' | 'INVESTMENT_FUNDS' | 'BONDS' | 'EQUITIES' | 'DERIVATIVES' | 'STRUCTURED_PRODUCTS' | 'NON_TRANSFERABLE_SECURITIES';
    export const NextRuleSetEnum = {
        CLIENT_CLASSIFICATION: 'CLIENT_CLASSIFICATION' as NextRuleSetEnum,
        CLIENT_IDENTIFICATION_NATURAL_PERSON: 'CLIENT_IDENTIFICATION_NATURAL_PERSON' as NextRuleSetEnum,
        CLIENT_IDENTIFICATION_NON_NATURAL_PERSON: 'CLIENT_IDENTIFICATION_NON_NATURAL_PERSON' as NextRuleSetEnum,
        OPERATIONAL_ACTIVITY: 'OPERATIONAL_ACTIVITY' as NextRuleSetEnum,
        TRANSFER_TERRITORIAL_SCOPING: 'TRANSFER_TERRITORIAL_SCOPING' as NextRuleSetEnum,
        DATA_CLASSIFICATION: 'DATA_CLASSIFICATION' as NextRuleSetEnum,
        DATA_TRANSFER_CONDITION: 'DATA_TRANSFER_CONDITION' as NextRuleSetEnum,
        DATA_TRANSFER: 'DATA_TRANSFER' as NextRuleSetEnum,
        SPECIAL_CONTEXT_DATA_TRANSFER: 'SPECIAL_CONTEXT_DATA_TRANSFER' as NextRuleSetEnum,
        BRAND_MARKETING: 'BRAND_MARKETING' as NextRuleSetEnum,
        CUSTODY_AND_PAYMENT: 'CUSTODY_AND_PAYMENT' as NextRuleSetEnum,
        DISCRIMINATORY_ASSET_MANAGEMENT: 'DISCRIMINATORY_ASSET_MANAGEMENT' as NextRuleSetEnum,
        INVESTMENT_ADVICE: 'INVESTMENT_ADVICE' as NextRuleSetEnum,
        LOANS_AND_CREDITS: 'LOANS_AND_CREDITS' as NextRuleSetEnum,
        RESEARCH: 'RESEARCH' as NextRuleSetEnum,
        SOCIAL_CONTACTS: 'SOCIAL_CONTACTS' as NextRuleSetEnum,
        WEBSITE: 'WEBSITE' as NextRuleSetEnum,
        DISTRIBUTION: 'DISTRIBUTION' as NextRuleSetEnum,
        WEALTH_PLANNING: 'WEALTH_PLANNING' as NextRuleSetEnum,
        WEALTH_MANAGEMENT_SERVICES: 'WEALTH_MANAGEMENT_SERVICES' as NextRuleSetEnum,
        E_BANKING_SERVICES: 'E_BANKING_SERVICES' as NextRuleSetEnum,
        TRUST_FICUCIARY_SERVICES: 'TRUST_FICUCIARY_SERVICES' as NextRuleSetEnum,
        INTERMEDIARIES: 'INTERMEDIARIES' as NextRuleSetEnum,
        CLIENT_ADMINISTRATION_AND_DOCUMENTS: 'CLIENT_ADMINISTRATION_AND_DOCUMENTS' as NextRuleSetEnum,
        DISTRIBUTION_STRUCTURED_PRODUCTS: 'DISTRIBUTION_STRUCTURED_PRODUCTS' as NextRuleSetEnum,
        COUNTRY_OVERVIEW_FINANCIAL_SERVICES: 'COUNTRY_OVERVIEW_FINANCIAL_SERVICES' as NextRuleSetEnum,
        COUNTRY_OVERVIEW_FINANCIAL_INSTRUMENTS: 'COUNTRY_OVERVIEW_FINANCIAL_INSTRUMENTS' as NextRuleSetEnum,
        BRAND_MARKETING_IB: 'BRAND_MARKETING_IB' as NextRuleSetEnum,
        WEBSITE_IB: 'WEBSITE_IB' as NextRuleSetEnum,
        CAPITAL_MARKET_TRANSACTION_SERVICE_IB: 'CAPITAL_MARKET_TRANSACTION_SERVICE_IB' as NextRuleSetEnum,
        FOREIGN_EXCHANGE_SERVICE_IB: 'FOREIGN_EXCHANGE_SERVICE_IB' as NextRuleSetEnum,
        TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB: 'TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB' as NextRuleSetEnum,
        TREASURY_CASH_BANKNOTE_SERVICES_IB: 'TREASURY_CASH_BANKNOTE_SERVICES_IB' as NextRuleSetEnum,
        PRECIOUS_METAL_SERVICES_IB: 'PRECIOUS_METAL_SERVICES_IB' as NextRuleSetEnum,
        RATES_SERVICES_IB: 'RATES_SERVICES_IB' as NextRuleSetEnum,
        ADVISORY_FOR_MERGERS_ACQUISITIONS_IB: 'ADVISORY_FOR_MERGERS_ACQUISITIONS_IB' as NextRuleSetEnum,
        DIGITAL_APPLICATIONS_SOLUTIONS_IB: 'DIGITAL_APPLICATIONS_SOLUTIONS_IB' as NextRuleSetEnum,
        CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB: 'CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB' as NextRuleSetEnum,
        BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB: 'BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB' as NextRuleSetEnum,
        TRADE_FINANCE_IB: 'TRADE_FINANCE_IB' as NextRuleSetEnum,
        CORRESPONDANCE_BANKING_IB: 'CORRESPONDANCE_BANKING_IB' as NextRuleSetEnum,
        INVESTMENT_FUNDS: 'INVESTMENT_FUNDS' as NextRuleSetEnum,
        BONDS: 'BONDS' as NextRuleSetEnum,
        EQUITIES: 'EQUITIES' as NextRuleSetEnum,
        DERIVATIVES: 'DERIVATIVES' as NextRuleSetEnum,
        STRUCTURED_PRODUCTS: 'STRUCTURED_PRODUCTS' as NextRuleSetEnum,
        NON_TRANSFERABLE_SECURITIES: 'NON_TRANSFERABLE_SECURITIES' as NextRuleSetEnum
    };
}


