import { StateContext } from '@ngxs/store';
import {
  JurisdictionLabelsWithVariables,
  Label,
  LELabelsWithVariables,
  MarketingMaterialStateModel
} from '../marketing-material.model';
import { MarketingMaterialResults } from '../../../../generated/apps-api';
import { LabelsObject, Products } from '../../../shared/state/shared.model';
import { distinct } from '../../../shared/utils/arrays.utils';
import { VariablesUtils } from '../../../shared/variables.utils';


export class SaveLabelsWithVariablesUsecase {
  public save(context: StateContext<MarketingMaterialStateModel>, results: MarketingMaterialResults, ruleSetLabels: {
    [key: string]: LabelsObject
  }): void {
    context.patchState({
      labelsWithVariables: this.getConceptsWithVariables(results, ruleSetLabels)
    });
  }

  private getConceptsWithVariables(results: MarketingMaterialResults, ruleSetLabels: {
    [key: string]: LabelsObject
  }): JurisdictionLabelsWithVariables[] {
    let labelWithVariables: JurisdictionLabelsWithVariables[] = [];

    results.jurisdictionResults.forEach(jurisdictionResults => {

      const jurisdiction = jurisdictionResults.jurisdiction;
      const legalEntityResults = jurisdictionResults.legalEntityResults;
      const leLabelsWithVariables: LELabelsWithVariables[] = [];
      legalEntityResults.forEach(legalEntityResults => {
        const legalEntityId = legalEntityResults.legalEntityId;
        const results = legalEntityResults.results;
        const termIds: string[] = results?.flatMap(result => {
          return result.actions?.flatMap(action => {
            return [...action?.reasons].filter(distinct);
          });
        }).filter(t => t);
        const labelsWithVariables = this.getLabelsWithVariables(termIds, ruleSetLabels[jurisdiction.concat(':').concat(Products.MARKETING_MATERIAL_DOCUMENT_CHECK)]);
        leLabelsWithVariables.push({
          legalEntityId: legalEntityId,
          variables: labelsWithVariables.variables,
          labels: labelsWithVariables.labels,
          hasLegalEntitiesVariables: !!labelsWithVariables.variables?.find(v => v.toLowerCase().startsWith(VariablesUtils.LegalEntityVariableStartsBy))
        });

      });
      labelWithVariables.push({
        jurisdiction: jurisdiction,
        leLabelsWithVariables: leLabelsWithVariables
      });

    });

    return labelWithVariables;
  }

  private getLabelsWithVariables(termIds: string[], labels: LabelsObject): { labels: Label[], variables: string[] } {
    const labelsWithVariables = termIds
      .map(t => t.split(':')[0].replace('[[', '').replace(']]', ''))
      .filter(distinct)
      .map(t => labels[t])
      .filter(l => l && JSON.stringify(l).includes(`\${`));

    const variables: string[] = JSON.stringify(labelsWithVariables)?.match(new RegExp(`\\$\\{(.*?)}`, 'g'))?.filter(distinct);
    return { labels: labelsWithVariables, variables: variables };
  }

}

