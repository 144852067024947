import { Pipe, PipeTransform } from '@angular/core';
import { LabelsResolverService } from '../services/labelsResolver.service';
import { ToLabelPipe } from './to-label.pipe';

@Pipe({
  name: 'toLabel',
  pure: false,
})
export class ImpureToLabelPipe extends ToLabelPipe implements PipeTransform {
  constructor(labelsResolverService: LabelsResolverService) {
    super(labelsResolverService);
  }
}
