import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthImagePipe } from './authImage.pipe';
import { ImpureToLabelPipe } from './impure-to-label.pipe';
import { ResolveReferencesPipe } from './resolveReferences.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ToLabelPipe } from './to-label.pipe';

@NgModule({
  declarations: [environment.production ? ToLabelPipe : ImpureToLabelPipe, SafeHtmlPipe, ResolveReferencesPipe, AuthImagePipe],
  imports: [CommonModule],
  providers: [{ provide: ToLabelPipe, useClass: environment.production ? ToLabelPipe : ImpureToLabelPipe }, SafeHtmlPipe, ResolveReferencesPipe,AuthImagePipe ],
  exports: [environment.production ? ToLabelPipe : ImpureToLabelPipe, SafeHtmlPipe, ResolveReferencesPipe, AuthImagePipe],
})
export class PipeModule {
  //
}
