import { StateContext } from '@ngxs/store';
import { LabelsObject, SharedStateModel, TaxonomyVariable } from '../shared.model';
import { cloneDeep } from 'lodash-es';

export class StoreRuleLabelUseCases {


  public assignNewRuleLabels(
    context: StateContext<SharedStateModel>,
    jurisdiction: string,
    ruleSetType: string,
    labels: LabelsObject,
  ) {


    const rulesLabels = cloneDeep(context.getState().rulesLabels);
    const jurisdictionLabels = rulesLabels[`${jurisdiction}:${ruleSetType}`] || {};

    rulesLabels[`${jurisdiction}:${ruleSetType}`] = Object.assign({}, jurisdictionLabels, labels);


    return context.patchState({
      rulesLabels: rulesLabels,
    });
  }
}
