import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { first, tap } from 'rxjs/operators';
import {
  Label,
  LabelsApiService,
  TaxonomyVariablesApiService,
  UserPreferences,
  UserPreferencesApiService,
  UserProfileService,
  WhiteLabelApiService,
  WhiteLabelConfig,
  WhiteLabelConfigResponse
} from '../../../generated/apps-api';
import {
  AddRuleSetLabels,
  CompleteLoading,
  JumpedSteps,
  LoadAllTaxonomyVariables,
  LoadRuleSetLabels,
  LoadUserPreferences,
  ResolveVariables,
  SetAuditCorrelationId,
  SetAuditResultLabels,
  SetCurrentAppInfo,
  SetCurrentAppName,
  SetCurrentWhiteLabelCompany,
  SetErrors,
  SetExternalGrant,
  SetLoggedIn,
  SetOrganizationId,
  SetSharedResults,
  SetSharedResultsLabels,
  SetSkipNextErrorHandling,
  SetStaticLabels,
  SetStepsToGoBackOnError,
  SetTestMode,
  SetWhiteLabelConfig,
  SetWhiteLabelConfigAvailable,
  SharedStartAgain,
  UpdateTestMode,
  UpdateUserLanguage,
  UpdateUserLegalEntity
} from './shared.action';
import { DEFAULT_STATE } from './shared.defaultState';
import {
  AppInfo,
  LabelsObject,
  SharedModelError,
  SharedResults,
  SharedStateModel,
  TaxonomyVariable
} from './shared.model';
import { ResolveTaxonomyVariablesUseCases } from './usecases/resolve-taxonomy-variables-usecases.state';
import { StoreRuleLabelUseCases } from './usecases/save-rule-labels.state';
import { SetSharedResultsLabelsUseCases } from './usecases/set-shared-results.labels.state';
import { LoadAllTaxonomyVariablesUseCases } from './usecases/load-all-taxonomy-variables-usecases.state';

@State<SharedStateModel>({
  name: 'shared',
  defaults: DEFAULT_STATE
})
@Injectable()
export class SharedState {

  private resolveTaxonomyVariablesUseCases: ResolveTaxonomyVariablesUseCases;
  private loadAllTaxonomyVariables: LoadAllTaxonomyVariablesUseCases;
  private storeRuleLabelUseCases: StoreRuleLabelUseCases;
  private setSharedResultsLabelsUseCases: SetSharedResultsLabelsUseCases;

  constructor(
    private labelsService: LabelsApiService,
    private userPreferencesService: UserPreferencesApiService,
    private userProfile: UserProfileService,
    private whiteLabelApiService: WhiteLabelApiService,
    taxonomyVariablesApiService: TaxonomyVariablesApiService
  ) {
    this.resolveTaxonomyVariablesUseCases = new ResolveTaxonomyVariablesUseCases(taxonomyVariablesApiService);
    this.loadAllTaxonomyVariables = new LoadAllTaxonomyVariablesUseCases(taxonomyVariablesApiService);
    this.storeRuleLabelUseCases = new StoreRuleLabelUseCases();
    this.setSharedResultsLabelsUseCases = new SetSharedResultsLabelsUseCases();
  }

  @Selector()
  public static currentAppInfo(state: SharedStateModel): AppInfo {
    return state.currentAppInfo;
  }

  @Selector()
  public static staticLabels(state: SharedStateModel): LabelsObject {
    return state.staticLabels;
  }

  @Selector()
  public static rulesLabels(state: SharedStateModel): { [key: string]: LabelsObject } {
    return state.rulesLabels;
  }

  @Selector()
  public static taxonomyVariables(state: SharedStateModel): TaxonomyVariable[] {
    return state.taxonomyVariables;
  }

  @Selector()
  public static allTaxonomyVariablesWithType(state: SharedStateModel): { identifier: string, type: string }[] {
    return state.allTaxonomyVariablesWithType;
  }

  @Selector()
  public static taxonomyVariablesContext(state: SharedStateModel): TaxonomyVariable[] {
    return state.taxonomyVariables;
  }

  @Selector()
  public static errors(state: SharedStateModel): SharedModelError {
    return state.errors;
  }

  @Selector()
  public static skipNextErrorHandling(state: SharedStateModel): boolean {
    return state.skipNextErrorHandling;
  }

  @Selector()
  public static isLoaded(state: SharedStateModel): boolean {
    return state.loaded;
  }

  @Selector()
  public static jumpedSteps(state: SharedStateModel): number[] {
    return state.jumpedSteps;
  }

  @Selector()
  public static whiteLabelConfig(state: SharedStateModel): WhiteLabelConfig {
    return state.whiteLabelConfig;
  }

  @Selector()
  public static loggedIn(state: SharedStateModel): boolean {
    return state.loggedIn;
  }

  @Selector()
  public static hasExternalGrant(state: SharedStateModel): boolean {
    return state.hasExternalGrant;
  }

  @Selector()
  public static sharedResults(state: SharedStateModel): SharedResults {
    return state.sharedResults;
  }

  @Selector()
  public static userPreferences(state: SharedStateModel): UserPreferences {
    return state.userPreferences;
  }

  @Selector()
  public static auditCorrelationId(state: SharedStateModel): string {
    return state.auditCorrelationId;
  }

  @Selector()
  public static isWhiteLabelConfigAvailable(state: SharedStateModel): boolean {
    return state.isWhiteLabelConfigAvailable;
  }

  @Selector()
  public static currentWhiteLabelCompanyId(state: SharedStateModel): string {
    return state.currentWhiteLabelCompanyId;
  }

  @Selector()
  public static organizationId(state: SharedStateModel): string {
    return state.organizationId;
  }

  @Action(CompleteLoading)
  public completeLoading(context: StateContext<SharedStateModel>) {
    return context.patchState({
      loaded: true
    });
  }

  @Action(SetTestMode)
  public setTestMode(context: StateContext<SharedStateModel>, { payload }: SetTestMode) {
    const testMode = payload?.testMode || false;
    const userPreferences = cloneDeep(context.getState().userPreferences);
    userPreferences.testMode = testMode;
    return context.patchState({ userPreferences });

  }

  @Action(UpdateTestMode)
  public updateTestMode(context: StateContext<SharedStateModel>, { payload }: SetTestMode) {
    const testMode = payload?.testMode || false;
    return this.userPreferencesService.updateTestMode(testMode).pipe(
      tap(() => {
        const userPreferences = cloneDeep(context.getState().userPreferences) || {};
        userPreferences.testMode = testMode;
        context.patchState({ userPreferences });
      })
    );
  }

  @Action(SetErrors)
  public setErrors(context: StateContext<SharedStateModel>, { payload }: SetErrors) {
    const errors = payload?.errors || DEFAULT_STATE.errors;
    // UN-3663
    if (payload?.errors?.code === 'MONITORING_ACCESS_EXPIRED') {
      errors.showGoBackButton = false;
    } else if (errors) {
      // just add the flag if exist errors
      // in some cases we are just cleaning the errors
      errors.showGoBackButton = true;
    }
    //errors.showGoBackButton = payload?.errors?.code === 'MONITORING_ACCESS_EXPIRED' ? false : true;
    return context.patchState({
      errors: errors,
      loaded: true
    });
  }

  @Action(SetStepsToGoBackOnError)
  public setStepsToGoBackOnError(context: StateContext<SharedStateModel>, { payload }: { payload: number }) {
    const errors = cloneDeep(context.getState().errors);
    errors.stepsToGoBack = payload;
    return context.patchState({
      errors,
      loaded: true
    });
  }

  @Action(SetSkipNextErrorHandling)
  public setSkipNextErrorHandling(context: StateContext<SharedStateModel>, { payload }: SetSkipNextErrorHandling) {
    return context.patchState({
      skipNextErrorHandling: payload.value
    });
  }

  @Action(AddRuleSetLabels)
  public addRuleSetLabels(context: StateContext<SharedStateModel>, { payload }: AddRuleSetLabels) {
    return this.labelsService.resolveLabels_1(payload.contentProviderId, payload.jurisdiction, payload.labelsIds).pipe(
      tap((result) => {
        this.storeRuleLabelUseCases.assignNewRuleLabels(context, payload.jurisdiction, payload.ruleSetType, result);
      })
    );
  }

  @Action(SetStaticLabels)
  public setStaticLabels(context: StateContext<SharedStateModel>, { payload }: SetStaticLabels) {
    const labels = Object.assign({}, context.getState().staticLabels, payload.labels);
    return context.patchState({
      staticLabels: labels
    });
  }

  @Action(LoadRuleSetLabels)
  public loadRuleSetLabels(context: StateContext<SharedStateModel>, { payload }: LoadRuleSetLabels) {
    return this.storeRuleLabelUseCases.assignNewRuleLabels(context, payload.jurisdiction, payload.ruleSetType, payload.labels);
  }

  @Action(ResolveVariables)
  public resolveVariables(context: StateContext<SharedStateModel>, { payload }: ResolveVariables) {
    return this.resolveTaxonomyVariablesUseCases.resolveVariables(context, payload.labels, payload.options);
  }

  @Action(LoadAllTaxonomyVariables)
  public loadVariables(context: StateContext<SharedStateModel>, { payload }: LoadAllTaxonomyVariables) {
    return this.loadAllTaxonomyVariables.load(context, payload.labels);
  }


  @Action(SetAuditResultLabels)
  public loadAuditResultLabels(context: StateContext<SharedStateModel>, { payload }: SetAuditResultLabels) {
    const { staticLabels, rulesLabels, taxonomyVariables } = payload?.state;
    if (staticLabels) context.patchState({
      staticLabels,
      rulesLabels,
      taxonomyVariables
    });
  }

  @Action(SetSharedResultsLabels)
  public loadSharedResultLabels(context: StateContext<SharedStateModel>, { payload }: SetSharedResultsLabels) {
    return this.setSharedResultsLabelsUseCases.setLabels(context, { payload });
  }

  @Action(JumpedSteps)
  public setJumpedSteps(context: StateContext<SharedStateModel>, { payload }: JumpedSteps) {
    return context.patchState({
      jumpedSteps: payload.jumpedSteps
    });
  }

  @Action(SharedStartAgain)
  public startAgain(context: StateContext<SharedStateModel>) {
    return context.patchState({
      jumpedSteps: undefined,
      rulesLabels: {},
      taxonomyVariables: [],
      allTaxonomyVariablesWithType: []
    });
  }

  @Action(SetWhiteLabelConfig)
  public setWhiteLabelConfig(context: StateContext<SharedStateModel>, { payload }: SetWhiteLabelConfig) {
    return context.patchState({
      whiteLabelConfig: payload.config
    });
  }

  @Action(SetOrganizationId)
  public setOrganizationId(context: StateContext<SharedStateModel>, { payload }: SetOrganizationId) {
    return context.patchState({
      organizationId: payload.organizationId
    });
  }

  @Action(SetLoggedIn)
  public setLoggedIn(context: StateContext<SharedStateModel>, { payload }: SetLoggedIn) {
    return context.patchState({
      loggedIn: payload.state
    });
  }

  @Action(LoadUserPreferences)
  public loadUserPreferences(context: StateContext<SharedStateModel>) {
    return this.userPreferencesService.getUserPreferences()
      .pipe(first(),
        tap(userPreferences =>
          context.patchState({
            userPreferences
          })));
  }

  @Action(SetExternalGrant)
  public setExternalGrant(context: StateContext<SharedStateModel>, { payload }: SetExternalGrant) {
    return context.patchState({
      hasExternalGrant: payload.state
    });
  }

  @Action(SetCurrentAppInfo)
  public setCurrentAppInfo(context: StateContext<SharedStateModel>, { payload }: SetCurrentAppInfo) {
    return context.patchState({
      currentAppInfo: {
        introUrl: payload.appInfo?.introUrl,
        loadContextAction: payload.appInfo?.loadContextAction,
        titleLabelId: payload.appInfo?.titleLabelId || context.getState()?.currentAppInfo?.titleLabelId
      }
    });
  }

  @Action(SetCurrentAppName)
  public setCurrentAppName(context: StateContext<SharedStateModel>, { payload }: SetCurrentAppName) {
    return context.patchState({
      currentAppInfo: {
        introUrl: context.getState().currentAppInfo.introUrl,
        loadContextAction: context.getState().currentAppInfo.loadContextAction,
        titleLabelId: payload.appName
      }
    });
  }

  @Action(SetSharedResults)
  public setSharedResults(context: StateContext<SharedStateModel>, { payload }: SetSharedResults) {
    return context.patchState({
      sharedResults: payload.state
    });
  }

  @Action(UpdateUserLanguage)
  public updateUserLanguage(context: StateContext<SharedStateModel>, { language }: UpdateUserLanguage) {
    return this.userProfile.updatePreferredLanguage(language)
      .pipe(
        first(),
        tap(() => {
          const newUserPreferences: UserPreferences = cloneDeep(context.getState().userPreferences);
          newUserPreferences.preferredLanguage = language;
          context.patchState({
            userPreferences: newUserPreferences
          });
        })
      );
  }

  @Action(UpdateUserLegalEntity)
  public updateUserLegalEntity(context: StateContext<SharedStateModel>, { legalEntityId }: UpdateUserLegalEntity) {
    return this.userProfile.updatePreferredLegalEntity(legalEntityId)
      .pipe(
        first(),
        tap(() => {
          const newUserPreferences: UserPreferences = cloneDeep(context.getState().userPreferences);
          newUserPreferences.preferredLegalEntityId = legalEntityId;
          context.patchState({
            userPreferences: newUserPreferences
          });
        })
      );
  }

  @Action(SetAuditCorrelationId)
  public auditSaveContext(ctx: StateContext<SharedStateModel>, { correlationId }: SetAuditCorrelationId) {
    return ctx.patchState({
      auditCorrelationId: correlationId
    });
  }

  @Action(SetWhiteLabelConfigAvailable)
  public isWhiteLabelConfigAvailable(ctx: StateContext<SharedStateModel>, { isWhiteLabelConfigAvailable }: SetWhiteLabelConfigAvailable) {
    return ctx.patchState({
      isWhiteLabelConfigAvailable: isWhiteLabelConfigAvailable
    });
  }

  @Action(SetCurrentWhiteLabelCompany)
  public setCurrentWhiteLabelCompany(ctx: StateContext<SharedStateModel>, { companyId }: SetCurrentWhiteLabelCompany) {
    return this.whiteLabelApiService.retrieveCompanyConfig(companyId).subscribe((configResponse: WhiteLabelConfigResponse) => {
      return ctx.patchState({
        currentWhiteLabelCompanyId: companyId,
        whiteLabelConfig: configResponse.whiteLabelConfig
      });
    });
  }


  public static retrieveRuleLabels(
    ruleLabels: { [key: string]: LabelsObject },
    jurisdiction: string,
    ruleSetType: string
  ): LabelsObject {
    return ruleLabels[`${jurisdiction}:${ruleSetType}`];
  }

  public static retrieveRuleLabel(
    ruleLabels: { [key: string]: LabelsObject },
    labelId: string,
    jurisdiction: string,
    ruleSetType: string
  ): Label {
    const labelsObject = this.retrieveRuleLabels(ruleLabels, jurisdiction, ruleSetType);
    return this.retrieveLabel(labelsObject, labelId);
  }

  public static retrieveLabel(labelsObject: LabelsObject, labelId: string): Label {
    if (!labelsObject || !labelId) return undefined;
    const key = Object.keys(labelsObject).find(label => label.toLowerCase() === labelId?.toLowerCase());
    return key === undefined ? undefined : labelsObject[key];
  }


}
