import { Configuration } from '../generated/apps-api';
import { AppWindow } from './AppConfig.model';

declare const window: AppWindow;

export const buildApiConfig = () => {
  const config = new Configuration();
  config.basePath = window.__config.apiUrl;
  config.apiKeys = {};
  return config;
};
