import { Pipe, PipeTransform } from '@angular/core';
import { LabelResolverContext, LabelsResolverService } from '../services/labelsResolver.service';

@Pipe({
  name: 'toLabel',
})
export class ToLabelPipe implements PipeTransform {
  constructor(protected labelsResolverService: LabelsResolverService) {}

  transform(value: string, context?: LabelResolverContext): string {
    return this.labelsResolverService.resolveValue(value, context);
  }
}
