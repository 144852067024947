<button
  type="button"
  class="btn"
  [disabled]="disabled"
  (click)="emitClick($event)"
  [ngClass]="classes"
  [style]="{ width: width }"
>
  <div class="d-flex align-items-center button-content" [ngClass]="contentClasses">
    <i *ngIf="icon && iconLocation === 'left'" [inlineSVG]="icon" [class]="'svg-' + iconLocation + '-' + iconClass"></i>
    <span [ngClass]="{underlined: underlined}">{{ labelId | toLabel }}</span>
    <i *ngIf="icon && iconLocation === 'right'" [inlineSVG]="icon" class="icon"></i>
  </div>
</button>
