import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ModelError } from 'src/generated/apps-api';
import { SetErrors, SetSkipNextErrorHandling } from '../../shared/state/shared.action';
import {SharedSelectors} from '../../shared/state/shared.selectors';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  public static readonly SKIPPED_ERRORS = ['WRONG_PASSWORD'];

  constructor(private router: Router, private store: Store, private modalService: NgbModal) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      //buisiness error: status = 200 but should show an error to the user
      map(response => {
        if (response instanceof HttpResponse && response.body?.isError) {
          this.handleError(response.status, response.body);
        }
        return response;
      }),
      // when there are http errors (status >= 300)
      catchError(error => {
        this.handleError(error.status, error.error);
        throw error;
      })
    );
  }

  private handleError(status: number, modelError: ModelError) {
    const shouldSkip = this.store.selectSnapshot(SharedSelectors.skipNextErrorHandling);
    if (shouldSkip) {
      this.store.dispatch(new SetSkipNextErrorHandling({ value: false }));
    } else if (ErrorInterceptor.SKIPPED_ERRORS.indexOf(modelError?.code) === -1) {
      console.error(modelError);
      this.store.dispatch(new SetErrors({ errors: this.buildModelError(status, modelError) })).subscribe(() => {
        if (!this.modalService.hasOpenModals()) this.router.navigate(['/errors']).then();
      });
    }
  }

  private defaultErrorMessage(): ModelError {
    return {
      titleLabelId: 'genericErrorApp',
    };
  }

  private defaultPermissionErrorMessage(): ModelError {
    return {
      titleLabelId: 'noPermissionToEvaluateRuleErrorApp',
    };
  }

  private buildModelError(status: number, error: ModelError): ModelError {
    if (error?.isError) return error;
    return status === 403 ? this.defaultPermissionErrorMessage() : this.defaultErrorMessage();
  }
}
