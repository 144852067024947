import { investorTypeQuestions } from '../components/flow/static-questions/investor-type.state.utils';
import { CrossBorderStateModel } from './cross-border.model';

export const DEFAULT_STATE: CrossBorderStateModel = {
  jurisdictions: [],
  instrumentsJurisdictions: [],
  allJurisdictions: [],
  products: [],
  scenarios: [
    {
      scenarioName: 'Initial Scenario',
      questions: [investorTypeQuestions[0]],
      attributes: [],
      results: {}
    },
  ],
  selectedProducts: [],
  currentStep: 0,
  countryOverviews: {},
  firstScenarioJurisdiction: undefined,
  isSuperlogic: false,
  phoneVisibleScenarioIndex: 0,
  selectedService: undefined,
  phoneShowProactive: true,
  savedContexts: undefined,
  savedContext: {
    isSavedContext: false
  },
  isCrossBorderIB: false,
  generatedOn: undefined,
  generatedByFullName: undefined,
};
