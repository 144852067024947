import { CBGlobalConstants } from 'src/app/cross-border/cross-border.globalConstants';
import { Option } from 'src/generated/apps-api';
import { CrossBorderAttribute } from 'src/generated/apps-api/model/crossBorderAttribute';
import { CrossBorderQuestion, Scenario } from '../../../state/cross-border.model';
import { INVESTOR_TYPE_QUESTION, REPRESENTATIVE_DOMICILE_QUESTION, REPRESENTATIVE_OF_LEGAL_ENTITY, REPRESENTATIVE_PHYSICAL_LOCATION_QUESTION, TYPE_OF_REPRESENTATIVE_QUESTION } from './cross-border-static-questions';


export const checkIfInvestorTypeAttribute = (newAttribute: CrossBorderAttribute, scenario: Scenario, options: Option[]) => {
  const { name, value } = newAttribute;
  if (name === INVESTOR_TYPE_QUESTION.property && value === REPRESENTATIVE_OF_LEGAL_ENTITY) {
    scenario.questions.push(TYPE_OF_REPRESENTATIVE_QUESTION);
  }
  if(CBGlobalConstants.isSuperlogic){
    checkIfSuperlogicInvestorTypeAttribute(newAttribute, scenario, options);
  }
};

export const isInvestorTypeAttribute = (property: string) => {
  return CBGlobalConstants.isSuperlogic? isSuperlogicInvestorTypeAttribute(property): property.endsWith('InvestorTypeSelection');
};

export const investorTypeQuestions: CrossBorderQuestion[] = [INVESTOR_TYPE_QUESTION, TYPE_OF_REPRESENTATIVE_QUESTION];

export const isRepresentativeOfLegalEntity = (scenario: Scenario): boolean =>{
  return scenario.attributes.some(a => a.name === INVESTOR_TYPE_QUESTION.property && a.value === REPRESENTATIVE_OF_LEGAL_ENTITY );
};

const superlogicInvestorTypeQuestions: CrossBorderQuestion[] = [INVESTOR_TYPE_QUESTION, TYPE_OF_REPRESENTATIVE_QUESTION, REPRESENTATIVE_DOMICILE_QUESTION, REPRESENTATIVE_PHYSICAL_LOCATION_QUESTION];

const isSuperlogicInvestorTypeAttribute = (property: string): boolean => {
  return !!superlogicInvestorTypeQuestions.find(s => s.property === property);
};

const checkIfSuperlogicInvestorTypeAttribute = (newAttribute: CrossBorderAttribute, scenario: Scenario, options: Option[]) => {
  const { name } = newAttribute;

  if(isRepresentativeOfLegalEntity(scenario)){
    if(name === TYPE_OF_REPRESENTATIVE_QUESTION.property){
      const question: CrossBorderQuestion = {
        ...REPRESENTATIVE_PHYSICAL_LOCATION_QUESTION,
        options,
      };
      scenario.questions.push(question);
    }
    if( name === REPRESENTATIVE_PHYSICAL_LOCATION_QUESTION.property){
      const question: CrossBorderQuestion = {
        ...REPRESENTATIVE_DOMICILE_QUESTION,
        options,
      };
      scenario.questions.push(question);
    }
  }
};