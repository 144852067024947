import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { LabelsResolverService } from 'src/app/shared/services/labelsResolver.service';
import { SharedStartAgain } from 'src/app/shared/state/shared.action';
import {
  MarketingMaterialAttribute,
  MarketingMaterialInstrument,
  MarketingMaterialResults,
  MMLegalEntity,
  Option,
  RuleSetsInfo,
  SupportedFootnotesAndDisclaimerLanguages
} from 'src/generated/apps-api';
import { MarketingMaterialExtendedQuestion } from '../models/marketingMaterialExtendedQuestion';
import { marketingMaterialAddQuestion } from '../services/marketing-material-questions.utils';
import {
  AddQuestion,
  SetAttributes,
  SetDistributionCountries,
  SetFilters,
  SetInstrument,
  SetIsInstrumentSearchable,
  SetLabelsWithVariables,
  SetLegalEntities,
  SetQuestions,
  SetResults,
  SetSelectedDisclaimerLanguages,
  SetSelectedDistributionCountries,
  SetSelectedDistributionCountriesWithResults,
  SetSelectedEntities,
  SetSelectedLegalEntitiesMode,
  SetStaticResult,
  SetSupportedDisclaimerLanguages
} from './marketing-material.action';
import { DEFAULT_STATE } from './marketing-material.defaultState';
import {
  JurisdictionLabelsWithVariables,
  LegalEntityMode,
  MarketingMaterialStateModel,
  SelectedDisclaimerLanguage,
  SelectedLegalEntity
} from './marketing-material.model';
import { getFilteredResults, getOptionsFromResults } from './marketing-material.state.utils';
import { SaveLabelsWithVariablesUsecase } from './usecases/save-labels-with-variables-usecase.state';

@State<MarketingMaterialStateModel>({
  name: 'marketingMaterial',
  defaults: DEFAULT_STATE
})
@Injectable()
export class MarketingMaterialState {
  constructor(private labelsResolverService: LabelsResolverService) {
  }

  @Selector()
  public static distributionCountries(state: MarketingMaterialStateModel): string[] {
    return state.distributionCountries;
  }

  @Selector()
  public static allCountries(state: MarketingMaterialStateModel): string[] {
    return state.allCountries;
  }

  @Selector()
  public static selectedDistributionCountries(state: MarketingMaterialStateModel): string[] {
    return state.selectedDistributionCountries;
  }

  @Selector()
  public static selectedDistributionCountriesWithResults(state: MarketingMaterialStateModel): string[] {
    return state.selectedDistributionCountriesWithResults;
  }

  @Selector()
  public static selectedLegalEntitiesIdsWithResults(state: MarketingMaterialStateModel): SelectedLegalEntity[] {
    return state.selectedLegalEntitiesIdsWithResults;
  }

  @Selector()
  public static selectedLegalEntitiesMode(state: MarketingMaterialStateModel): LegalEntityMode {
    return state.selectedLegalEntitiesMode;
  }

  @Selector()
  public static legalEntities(state: MarketingMaterialStateModel): MMLegalEntity[] {
    return state.legalEntities;
  }

  @Selector()
  public static ruleSetsInfo(state: MarketingMaterialStateModel): RuleSetsInfo[] {
    return state.ruleSetsInfo;
  }

  @Selector()
  public static attributes(state: MarketingMaterialStateModel): MarketingMaterialAttribute[] {
    return state.attributes;
  }

  @Selector()
  public static questions(state: MarketingMaterialStateModel): MarketingMaterialExtendedQuestion[] {
    return state.questions;
  }

  @Selector()
  public static results(state: MarketingMaterialStateModel): MarketingMaterialResults {
    return state.results;
  }

  @Selector()
  public static labelsWithVariables(state: MarketingMaterialStateModel): JurisdictionLabelsWithVariables[] {
    return state.labelsWithVariables;
  }

  @Selector()
  public static filteredResults(state: MarketingMaterialStateModel): MarketingMaterialResults {
    return getFilteredResults(state);
  }

  @Selector()
  public static applicablePerformanceDataOptions(state: MarketingMaterialStateModel): Option[] {
    return state.applicablePerformanceDataOptions;
  }

  @Selector()
  public static applicableCustomActionTagsOptions(state: MarketingMaterialStateModel): Option[] {
    return state.applicableCustomActionTagsOptions;
  }

  @Selector()
  public static performanceDataValues(state: MarketingMaterialStateModel): string[] {
    return state.performanceDataValues;
  }

  @Selector()
  public static customActionsTagValues(state: MarketingMaterialStateModel): string[] {
    return state.customActionsTagValues;
  }

  @Selector()
  public static shouldGoToStep2(state: MarketingMaterialStateModel): boolean {
    //Checking if there are more then 2 options for performance data and custom action tags because one of them is
    //always the none option
    //Meaning if there is only one perfromance tag then the length will be 2 since there is the none option -> only if there are multiple tags
    //this will return true
    return state.applicableCustomActionTagsOptions.length > 2 || state.applicablePerformanceDataOptions.length > 2;
  }

  @Selector()
  public static supportedDisclaimerLanguages(state: MarketingMaterialStateModel): SupportedFootnotesAndDisclaimerLanguages[] {
    return state.disclaimerLanguages.supportedDisclaimerLanguages;
  }

  @Selector()
  public static selectedDisclaimerLanguages(state: MarketingMaterialStateModel): SelectedDisclaimerLanguage[] {
    return state.disclaimerLanguages.selectedDisclaimerLanguages;
  }

  @Selector()
  public static selectInstrument(state: MarketingMaterialStateModel): MarketingMaterialInstrument {
    return state.instruments;
  }

  @Selector()
  public static isSearchInstrumentEnable(state: MarketingMaterialStateModel): boolean {
    return state.isSearchInstrumentEnable;
  }

  @Selector()
  public static generatedOn(state: MarketingMaterialStateModel): string {
    return state.generatedOn;
  }

  @Selector()
  public static generatedByFullName(state: MarketingMaterialStateModel): string {
    return state.generatedByFullName;
  }

  @Action(SetDistributionCountries)
  public setDistributionCountries(context: StateContext<MarketingMaterialStateModel>, { payload }: SetDistributionCountries) {
    return context.patchState({ distributionCountries: payload.countries, allCountries: payload.allCountries });
  }

  @Action(SetSelectedDistributionCountries)
  public setSelectedDistributionCountries(context: StateContext<MarketingMaterialStateModel>, { payload }: SetDistributionCountries) {
    return context.patchState({
      selectedDistributionCountries: payload.countries,
      selectedLegalEntitiesIdsWithResults: undefined,
      selectedLegalEntitiesMode: context.getState()?.selectedLegalEntitiesMode || LegalEntityMode.PER_COUNTRY,
      questions: undefined,
      attributes: undefined
    });
  }

  @Action(SetSelectedDistributionCountriesWithResults)
  public setSelectedDistributionCountriesWithResults(context: StateContext<MarketingMaterialStateModel>, { payload }: SetSelectedDistributionCountriesWithResults) {
    return context.patchState({
      selectedDistributionCountriesWithResults: payload.countries
    });
  }

  @Action(SetSelectedEntities)
  public setSelectedLegalEntities(context: StateContext<MarketingMaterialStateModel>, { payload }: SetSelectedEntities) {
    return context.patchState({
      selectedLegalEntitiesIdsWithResults: payload.legalEntitiesIds,
      questions: undefined,
      attributes: undefined
    });
  }

  @Action(SetSelectedLegalEntitiesMode)
  public setSelectedLegalEntitiesMode(context: StateContext<MarketingMaterialStateModel>, { payload }: SetSelectedLegalEntitiesMode) {
    return context.patchState({
      selectedLegalEntitiesMode: payload?.legalEntityMode
    });
  }

  @Action(SetLegalEntities)
  public setLegalEntities(context: StateContext<MarketingMaterialStateModel>, { payload }: SetLegalEntities) {
    return context.patchState({ legalEntities: payload.legalEntities });
  }

  @Action(SetAttributes)
  public setAttributes(context: StateContext<MarketingMaterialStateModel>, { payload }: SetAttributes) {
    return context.patchState({ attributes: payload.attributes });
  }

  @Action(AddQuestion)
  public addQuestion(context: StateContext<MarketingMaterialStateModel>, { payload }: AddQuestion) {
    const oldQuestions = cloneDeep(context.getState().questions) || [];
    const questions = marketingMaterialAddQuestion(oldQuestions, payload.question, context.getState().allCountries);
    return context.patchState({ questions });
  }

  @Action(SetQuestions)
  public setQuestions(context: StateContext<MarketingMaterialStateModel>, { payload }: SetQuestions) {
    return context.patchState({ questions: payload.questions });
  }

  @Action(SetResults)
  public setResults(context: StateContext<MarketingMaterialStateModel>, { payload }: SetResults) {
    const { results, ruleSetsInfo, generatedOn, generatedByFullName } = payload;

    const {
      applicableCustomActionTagsOptions,
      applicablePerformanceDataOptions
    } = getOptionsFromResults(results.jurisdictionResults, this.labelsResolverService);

    return context.patchState({
      results,
      ruleSetsInfo,
      applicableCustomActionTagsOptions,
      applicablePerformanceDataOptions,
      generatedOn,
      generatedByFullName
    });
  }

  @Action(SharedStartAgain)
  public startAgain(context: StateContext<MarketingMaterialStateModel>) {
    return context.setState(DEFAULT_STATE);
  }

  @Action(SetFilters)
  public setFilters(context: StateContext<MarketingMaterialStateModel>, { payload }: SetFilters) {
    return context.patchState(payload);
  }

  @Action(SetStaticResult)
  public setStaticResult(context: StateContext<MarketingMaterialStateModel>, { payload }: SetStaticResult) {
    return context.setState(payload.state);
  }

  @Action(SetSupportedDisclaimerLanguages)
  public setSupportedDisclaimerLanguages(context: StateContext<MarketingMaterialStateModel>, { payload }: SetSupportedDisclaimerLanguages) {
    const disclaimerLanguages = cloneDeep(context.getState().disclaimerLanguages);
    disclaimerLanguages.supportedDisclaimerLanguages = payload.supportedFootnotesAndDisclaimerLanguages;
    return context.patchState({ disclaimerLanguages });
  }

  @Action(SetSelectedDisclaimerLanguages)
  public setSelectedDisclaimerLanguages(context: StateContext<MarketingMaterialStateModel>, { payload }: SetSelectedDisclaimerLanguages) {
    const disclaimerLanguages = cloneDeep(context.getState().disclaimerLanguages);
    disclaimerLanguages.selectedDisclaimerLanguages = payload.selectedDisclaimerLanguages;

    return context.patchState({ disclaimerLanguages });
  }

  @Action(SetInstrument)
  public setInstrument(context: StateContext<MarketingMaterialStateModel>, { payload }: SetInstrument) {
    return context.patchState({ instruments: payload.instrument });
  }

  @Action(SetIsInstrumentSearchable)
  public setIsInstrumentSearchable(context: StateContext<MarketingMaterialStateModel>, { payload }: SetIsInstrumentSearchable) {
    return context.patchState({
      isSearchInstrumentEnable: payload.isInstrumentSearchable
    });
  }

  @Action(SetLabelsWithVariables)
  public setLabelsWithVariables(context: StateContext<MarketingMaterialStateModel>, { payload }: SetLabelsWithVariables) {
    const { ruleSetLabels } = payload;

    return new SaveLabelsWithVariablesUsecase().save(context, getFilteredResults(context.getState()), ruleSetLabels);
  }
}
