import { Question } from 'src/generated/apps-api';
import {
  CLIENT_DOMICILE_QUESTION,
  INVESTOR_TYPE_QUESTION,
  PRESENT_CLIENT_COUNTRY_QUESTION,
  PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION
} from '../components/flow/static-questions/cross-border-static-questions';
import { CBGlobalConstants } from '../cross-border.globalConstants';

const regpropsPerStep = [
  [INVESTOR_TYPE_QUESTION.property],
  [PRESENT_CLIENT_COUNTRY_QUESTION.property, PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION.property],
  ['bookingLegalEntity', 'financialInstituteLicense', 'personalLicense', 'chaperoningLicense'],
  ['communicationChannel', 'serviceChannel', 'numberOfInteractions', 'clientInteractionType', 'entityType','capitalMarket'],
  [
    'clientResident',
    'clientType',
    CLIENT_DOMICILE_QUESTION.property,
    'trusteeDomicile',
    'operatingBranchDomicile',
    'boDomicile',
    'pepStatus',
    'clientNationality',
    'clientStatus',
    'clientServiceType',
    'serviceStatus'
  ],
  ['clientCategory', 'registration', 'clientExemption', 'clientSegment', 'clientCode'],
  ['serviceCategory','existingService'],
  ['legalStructure'],
  ['fundType', 'instrumentType', 'instrumentDomicile'],
  ['financialInstrumentCurrency', 'issuer', 'issuerDomicile', 'issuerEqualsDistributor', 'openEndedOrClosed'],
  ['salesRegistration', 'salesRegistrationType', 'otherRegistration'],
  [
    'nonMainstreamPooledInvestmentNmpi',
    'euFundDirective',
    'euFundType',
    'euMifIdComplexity',
    'capitalMarket',
    'prospectusPublished',
    'listing',
    'listingCountry',
    'ExchangeIdentifier',
    'payingAgent',
    'representative',
    'placementAgent',
  ],
  ['inducement', 'underlying', 'inducements', 'leverageFactor', 'mandatoryClientDocuments'],
  ['denominationValue', 'numberOfOfferees', 'numberOfOffereesPerEeaState', 'investmentAmount', 'totalConsiderationAllEea', 'totalConsiderationEachOfferEea', 'totalAmountRaised'],
  ['apiaxProduct', 'regulatoryProductInformationDocument', 'mandatoryHandout'],
];

const regpropsPerStepSuperlogic = [
  regpropsPerStep[0],
  [PRESENT_CLIENT_COUNTRY_QUESTION.property, PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION.property, CLIENT_DOMICILE_QUESTION.property],
  regpropsPerStep[2],
  regpropsPerStep[3],
  ['clientResident', 'clientType', 'trusteeDomicile', 'operatingBranchDomicile', 'boDomicile', 'pepStatus', 'clientNationality', 'clientStatus', 'clientServiceType', 'serviceStatus'],
  regpropsPerStep[5],
  regpropsPerStep[6],
  regpropsPerStep[7],
  regpropsPerStep[8],
  regpropsPerStep[9],
  regpropsPerStep[10],
  regpropsPerStep[11],
  regpropsPerStep[12],
  regpropsPerStep[13],
  regpropsPerStep[14],
];

export const getStepNumber = (regProp: string) => getRegpropsPerStep().findIndex(stepRegprops => stepRegprops.indexOf(regProp) > -1) + 1; //step numbers is a 1-based index

export const getQuestionOrder = (regProp: string) =>
  getRegpropsPerStep()
    .find(step => step.indexOf(regProp) > -1)
    ?.indexOf(regProp);

export const stepContainsQuestion = (currentStepIndex: number, q: Question) => {
  return (
    getRegpropsPerStep()
      [currentStepIndex].map(repProp => repProp.toLowerCase())
      .indexOf(q.property.toLowerCase()) > -1
  );
};

export const getNextStepNumber = (availableQuestions: Question[], currentStepNumber: number, maxStepAllowed: number): number => {
  const idx = nextQuestionIndex(availableQuestions, currentStepNumber, maxStepAllowed);
  return idx + 1; //step numbers is a 1-based index
};

export const hasQuestionOnNextStep = (availableQuestions: Question[], currentStepNumber: number, maxStepAllowed: number): boolean => {
  const idx = nextQuestionIndex(availableQuestions, currentStepNumber, maxStepAllowed);
  return idx >= 0;
};

export const nextQuestionIndex = (availableQuestions: Question[], currentStepNumber: number, maxStepAllowed: number): number => {
  const questionsProperties = availableQuestions.map(q => q.property.toLowerCase());
  return getRegpropsPerStep().findIndex((stepProps, i) =>
    i >= currentStepNumber && i <= maxStepAllowed && stepProps.some(p => questionsProperties.indexOf(p.toLowerCase()) > -1));
};


const getRegpropsPerStep = () => {
  const isSuperlogic = CBGlobalConstants.isSuperlogic;
  return isSuperlogic ? regpropsPerStepSuperlogic : regpropsPerStep;
};
