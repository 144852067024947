import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { WhiteLabelConfig } from '../../../../generated/apps-api';
import { SharedSelectors } from '../../state/shared.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header.phone.component.scss', './header.tablet.component.scss'],
})
export class HeaderComponent {

  @Input() titleLabelId = '';
  @Input() defaultTitle = '';

  public whiteLabelConfig: Observable<WhiteLabelConfig>;

  constructor(private store: Store) {
    this.whiteLabelConfig = this.store.select<WhiteLabelConfig>(SharedSelectors.whiteLabelConfig);
  }

  public hasCompanyLogo(config: WhiteLabelConfig): boolean {
    return config?.enabled && !!config?.headerLogoSvg;
  }
}
