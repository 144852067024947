<div class="header">
  <div
    class="logo apiax-logo desktop-logo"
    *ngIf="!hasCompanyLogo(whiteLabelConfig | async)"
    [inlineSVG]="'assets/apiax-logo-white.svg'"
  ></div>
  <div
    class="logo apiax-logo mobile-logo"
    *ngIf="!hasCompanyLogo(whiteLabelConfig | async)"
    [inlineSVG]="'assets/apiax-logo-mobile.svg'"
  ></div>
  <div
    *ngIf="hasCompanyLogo(whiteLabelConfig | async)"
    class="logo custom-logo desktop-logo"
    [innerHTML]="(whiteLabelConfig | async)?.headerLogoSvg | safeHtml"
  ></div>
  <div
    *ngIf="hasCompanyLogo(whiteLabelConfig | async)"
    class="logo custom-logo mobile-logo"
    [innerHTML]="((whiteLabelConfig | async)?.phoneLogoSvg || (whiteLabelConfig | async)?.headerLogoSvg)  | safeHtml"
  ></div>
  <div class="separator" *ngIf="titleLabelId"></div>
  <div class="app-name" *ngIf="titleLabelId">{{ titleLabelId | toLabel: { defaultValue: defaultTitle } }}</div>
  <app-hamburger-menu></app-hamburger-menu>
</div>
