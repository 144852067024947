import { CBGlobalConstants } from 'src/app/cross-border/cross-border.globalConstants';
import { CrossBorderAttribute, CrossBorderProduct, Option, Question } from 'src/generated/apps-api';
import { CrossBorderQuestion, Scenario } from '../../../state/cross-border.model';
import { CLIENT_DOMICILE_QUESTION, PRESENT_CLIENT_COUNTRY_QUESTION, PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION } from './cross-border-static-questions';
import { isRepresentativeOfLegalEntity } from './investor-type.state.utils';


const questions = [PRESENT_CLIENT_COUNTRY_QUESTION, PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION];
const superlogicQuestions = [PRESENT_CLIENT_COUNTRY_QUESTION, PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION, CLIENT_DOMICILE_QUESTION];

export const isFinancialServicesStaticQuestion = (property: string) => {
  return CBGlobalConstants.isSuperlogic ? !!superlogicQuestions?.find(q => q.property === property) : !!questions?.find(q => q.property === property);
};

export const getFinancialServicesFirstQuestion = (scenario: Scenario): Question => {
  return CBGlobalConstants.isSuperlogic && isRepresentativeOfLegalEntity(scenario) ? PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION : PRESENT_CLIENT_COUNTRY_QUESTION;
};

export const checkIfStaticQuestion = (newAttribute: CrossBorderAttribute, scenario: Scenario, options: Option[]) => {
  if (CBGlobalConstants.isSuperlogic) {
    if(newAttribute.name === PRESENT_CLIENT_COUNTRY_QUESTION.property){
      const question: CrossBorderQuestion = {
        ...PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION,
        options,
      };
      scenario.questions.push(question);
    } else if (newAttribute.name === PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION.property) {
        const question: CrossBorderQuestion = {
          ...CLIENT_DOMICILE_QUESTION,
          options,
        };
        scenario.questions.push(question);
    }
  } else{
    if (newAttribute.name === PRESENT_CLIENT_COUNTRY_QUESTION.property) {
      const question: CrossBorderQuestion = {
        ...PRESENT_PRESENT_FI_EMPLOYEE_COUNTRY_QUESTION,
        options,
      };
      scenario.questions.push(question);
    }
  }
};

//documents
const clientAdminDocsRuleSets = [CrossBorderProduct.RulesetEnum.INVESTMENT_ADVICE, CrossBorderProduct.RulesetEnum.CUSTODY_AND_PAYMENT,
CrossBorderProduct.RulesetEnum.LOANS_AND_CREDITS, CrossBorderProduct.RulesetEnum.DISCRIMINATORY_ASSET_MANAGEMENT];
export const useClientAdminDocsRule: (products: CrossBorderProduct.RulesetEnum[]) => boolean = products => products.some(p => clientAdminDocsRuleSets.indexOf(p) > -1);

//documents IB
const clientAdminDocsIBRuleSets = [CrossBorderProduct.RulesetEnum.FOREIGN_EXCHANGE_SERVICE_IB, CrossBorderProduct.RulesetEnum.PRECIOUS_METAL_SERVICES_IB,
  CrossBorderProduct.RulesetEnum.RATES_SERVICES_IB, CrossBorderProduct.RulesetEnum.TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB,
  CrossBorderProduct.RulesetEnum.CAPITAL_MARKET_TRANSACTION_SERVICE_IB,
  CrossBorderProduct.RulesetEnum.ADVISORY_FOR_MERGERS_ACQUISITIONS_IB,
  CrossBorderProduct.RulesetEnum.TREASURY_CASH_BANKNOTE_SERVICES_IB,
  CrossBorderProduct.RulesetEnum.BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB,
  CrossBorderProduct.RulesetEnum.TRADE_FINANCE_IB];
export const useClientAdminDocsIBRule: (products: CrossBorderProduct.RulesetEnum[]) => boolean = products => products.some(p => clientAdminDocsIBRuleSets.indexOf(p) > -1);
