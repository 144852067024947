import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
    @Input()
    public value: boolean;
    @Output()
    public toggled = new EventEmitter<boolean>();

    public isActive: boolean;

    ngOnInit(): void {
        this.isActive = this.value;
    }

    public toggle(): void {
        this.isActive = !this.isActive;
        this.toggled.emit(this.isActive);
        
    }

    public get label(){
        return this.isActive? 'On':'Off';
    }
}