import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanMatch, GuardResult, MaybeAsync, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { intersection } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard implements CanMatch {
  constructor(
    protected readonly router: Router,
    protected readonly keycloakAngular: KeycloakService
  ) {
    super(router, keycloakAngular);
  }

  public canMatch(route: Route, segments: UrlSegment[]): MaybeAsync<GuardResult> {
    return this.isAccessAllowed();
  }

  public async isAccessAllowed(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    if (!this.authenticated) {
      await this.keycloakAngular.login();
    }

    if (route?.routeConfig?.path === 'errors') {
      return true;
    }

    const activities: string[] = (
      this.keycloakAngular.getKeycloakInstance().tokenParsed as {
        [key: string]: string[];
      }
    ).activities;

    return activities.indexOf(route.data?.activity) > -1 || intersection(activities, route.data?.activities).length > 0;
  }
}
