export enum PortalAction {
  LOAD_CONTEXT = '[Load portal context] Loads the portal context information',
}

export class LoadPortalContext {
  public static readonly type = PortalAction.LOAD_CONTEXT;
}



