import { Store } from '@ngxs/store';
import { SetStaticResultPayload } from 'src/app/cross-border/state';
import { CrossBorderInstrumentsSelectors } from '../../../cross-border/state/cross-border-instruments.selectors';
import { CrossBorderSelectors } from '../../../cross-border/state/cross-border.selectors';
import { SharedSelectors } from '../../state/shared.selectors';

const STATIC_RESULTS_URL = '/shared-result/';
const ERRORS_URL = '/errors';
const INTRO_URL = '/intro';
const FINANCIAL_SERVICES_URL = '/financial-services';

export const isIntroPage = (url: string): boolean => {
  return url.indexOf(INTRO_URL) !== -1 || url.indexOf(FINANCIAL_SERVICES_URL) !== -1;
};

export const isStaticResultPage = (url: string): boolean => {
  return url.indexOf(STATIC_RESULTS_URL) !== -1 || url.endsWith(ERRORS_URL);
};

export const buildCbStaticResultState: (store: Store) => () => SetStaticResultPayload = (store) => {
  return () => {
    const instrumentResults = store.selectSnapshot(CrossBorderInstrumentsSelectors.results);


    return {
      state: store.selectSnapshot(CrossBorderSelectors.scenarios),
      products: store.selectSnapshot(CrossBorderSelectors.products),
      countryOverviews: store.selectSnapshot(CrossBorderSelectors.countryOverviews),
      selectedService: store.selectSnapshot(CrossBorderSelectors.selectedService),
      firstScenarioJurisdiction: store.selectSnapshot(CrossBorderSelectors.firstScenarioJurisdiction),
      scenarios: store.selectSnapshot(CrossBorderSelectors.scenarios),
      isCrossBorderIB: store.selectSnapshot(CrossBorderSelectors.isCrossBorderIB),
      // Instruments
      instrumentsActivities:  instrumentResults,
      instrumentsProducts: store.selectSnapshot(CrossBorderInstrumentsSelectors.instrumentsProducts),
      instrumentsEvaluationInformation: !!instrumentResults ? store.selectSnapshot(CrossBorderInstrumentsSelectors.evaluationInformation) : undefined,
      instrumentsAttributes: store.selectSnapshot(CrossBorderInstrumentsSelectors.attributes),
      instrumentsQuestions: store.selectSnapshot(CrossBorderInstrumentsSelectors.questions),
      portfolioEntries: store.selectSnapshot(CrossBorderInstrumentsSelectors.portfolioEntries),
      usePortfolio: store.selectSnapshot(CrossBorderInstrumentsSelectors.usePortfolio),
      showOverlay: false // never show the banner on static results
    };
  };
};

export const cbAuditData = (store: Store) => {
  return {
    ...buildCbStaticResultState(store)(),
    staticLabels: store.selectSnapshot(SharedSelectors.staticLabels),
    rulesLabels: store.selectSnapshot(SharedSelectors.rulesLabels),
    taxonomyVariables: store.selectSnapshot(SharedSelectors.taxonomyVariables),
    currentAppInfo: store.selectSnapshot(SharedSelectors.currentAppInfo),
    generatedOn: store.selectSnapshot(CrossBorderSelectors.generatedOn)
  };
};
