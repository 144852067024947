import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss', './button.phone.component.scss'],
})
export class ButtonComponent implements OnInit {
  constructor() {}

  @Input() type: 'primary' | 'secondary' | 'warn' | 'link' = 'primary';
  @Input() disabled = false;
  @Input() size: 'lg' | 'sm' | 'md' = 'sm';
  @Input() labelId = '';
  @Input() icon = '';
  @Input() iconLocation: 'left' | 'right' = 'right';
  @Input() iconClass: 'normal' | 'small' = 'normal';
  @Input() width = '100%';
  @Input() justifyContent: 'space-between' | 'center' = 'space-between';
  @Input() underlined = false;

  @Output() clicked = new EventEmitter<Event>();

  public get classes(): string[] {
    let mode = 'btn-primary';
    if (this.type === 'secondary') {
      mode = 'btn-outline-primary';
    } else if (this.type === 'link') {
      mode = 'btn-link';
    } else if (this.type === 'warn') {
      mode = 'btn-outline-danger';
    }

    let buttonClass = `btn-${this.size}`;
    if (!this.icon) {
      buttonClass += '-no-icon';
    } else if (this.iconLocation === 'left') {
      buttonClass += '-icon-left';
    } else if (this.iconLocation === 'right') {
      buttonClass += '-icon-right';
    }

    return [mode, buttonClass, 'apx-button'];
  }

  public emitClick(event: Event): void {
    if(!this.disabled) this.clicked.emit(event);
  }

  public get contentClasses(): string[] {
    return [this.justifyContent];
  }

  ngOnInit(): void {}
}
