import { Directive, HostListener, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first, switchMap } from 'rxjs/operators';
import { LabelResolverContext, LabelsResolverService } from '../../services';
import { LabelsResolverModalComponent } from '../labels-resolver/modal/labels-resolver-modal.component';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[appShowTooltipModal]',
})
export class ShowTooltipModalDirective {
  constructor(
    private modalService: NgbModal,
    private labelsResolver: LabelsResolverService,
    private tooltipComponent: TooltipComponent
  ) {
  }

  @Input() appShowTooltipModal: {
    labelId: string;
    description?: string;
    ruleSet?: string;
    jurisdiction?: string | string[];
    contentProviderId?: string;
    modalOpenCallback?: (a: boolean) => void
  };

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopImmediatePropagation();
    if (this.appShowTooltipModal.modalOpenCallback) {
      this.appShowTooltipModal.modalOpenCallback(true);
    }

    const context = this.buildContext();

      this.labelsResolver
        .resolveLabelId(
          this.appShowTooltipModal.labelId,
          context
        )
        .pipe(first(), switchMap((result) => {
          const modalRef = this.modalService.open(LabelsResolverModalComponent, {
            centered: true,
            animation: false,
            windowClass: 'tooltip-modal',
          });

          modalRef.componentInstance.content = this.appShowTooltipModal?.description || result.description
          modalRef.componentInstance.ruleSet = context.ruleSet;
          modalRef.componentInstance.jurisdiction = context.jurisdiction;
          modalRef.componentInstance.title = result.title;
          modalRef.componentInstance.contentProviderId = context.contentProviderId;
          return modalRef.dismissed;
        })).subscribe(() => {
        if (this.appShowTooltipModal.modalOpenCallback) {
          this.appShowTooltipModal.modalOpenCallback(false);
        }
      })
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: Event) {
    event.stopImmediatePropagation();
    this.tooltipComponent.showTooltipModal = true;
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: Event) {
    event.stopImmediatePropagation();
    this.tooltipComponent.showTooltipModal = false;
  }

  private buildContext(): LabelResolverContext {
    return {
      ruleSet: this.appShowTooltipModal.ruleSet,
      jurisdiction: this.appShowTooltipModal.jurisdiction,
      discardReplaceReferences: true,
      contentProviderId: this.appShowTooltipModal.contentProviderId,
    };
  }
}
