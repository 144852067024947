import { Injectable, InjectionToken } from '@angular/core';
import { AgentConfigOptions } from "@elastic/apm-rum";
import { get as _get, set as _set } from 'lodash-es';
import { AnalyticsConfig, BaseConfig } from './config.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: BaseConfig;

  constructor() {
    this.config = _get(window, '__config', {}) as BaseConfig;
  }

  public load(config = _get(window, '__config', {}) as BaseConfig) {
    this.config = config;
    return config;
  }

  public getAppConfig() {
    return this.config;
  }

  public get(id: string): any {
    return _get(this.config, id);
  }

  public set(id: string, value: any) {
    _set(this.config, id, value);
  }

  public getAnalyticsConfig() {
    return this.get('analytics') as AnalyticsConfig;
  }

  public getApmConfig() {
    return this.get('apm') as AgentConfigOptions;
  }
}

export const CONFIG_SERVICE_TOKEN = new InjectionToken<ConfigService>('BaseConfigService', {
  providedIn: 'root',
  factory: () => {
    const service = new ConfigService();
    service.load();
    return service;
  }
});
