import { cloneDeep, isEqual } from 'lodash-es';
import { MarketingMaterialAttribute, MarketingMaterialInstrument, MarketingMaterialQuestion } from 'src/generated/apps-api';
import { MarketingMaterialExtendedQuestion } from '../models/marketingMaterialExtendedQuestion';
import { MarketingMaterialOption } from '../models/marketingMaterialOption';
import { isSameLicense } from '../state/marketing-material-results.utils';
import { convertOptionToInstrument, updateDataAccordingToIsin } from './marketing-material-instruments.utils';

export const marketingMaterialUpdateAttributes = (
  updatedData: { marketingMaterialAttribute: MarketingMaterialAttribute; marketingMaterialQuestion: MarketingMaterialExtendedQuestion },
  existingData: { attributes: MarketingMaterialAttribute[]; questions: MarketingMaterialExtendedQuestion[] }
): { attributes: MarketingMaterialAttribute[]; questions: MarketingMaterialQuestion[] } => {
  let { attributes, questions } = cloneDeep(existingData);
  const { marketingMaterialAttribute, marketingMaterialQuestion } = cloneDeep(updatedData);

  if (marketingMaterialQuestion) {
    delete marketingMaterialQuestion?.helpComponent;
    questions.forEach(_q => delete _q?.helpComponent);
    questions = questions.filter((_q, i) => i <= questions.findIndex(value => isEqual(value, marketingMaterialQuestion)));
  }

  const updatedAttributeIndex = attributes.findIndex(attr => attr.name === marketingMaterialAttribute.name && isSameLicense(attr, marketingMaterialAttribute));
  if (updatedAttributeIndex > -1) {
    attributes = attributes.filter((_q, i) => i < updatedAttributeIndex);
  }

  attributes = attributes.concat(marketingMaterialAttribute);

  return { attributes, questions };
};

export const marketingMaterialAddQuestion = (
  existingQuestions: MarketingMaterialExtendedQuestion[],
  question: MarketingMaterialExtendedQuestion,
  allCountries: string[]
): MarketingMaterialExtendedQuestion[] => {
  const oldQuestions = cloneDeep(existingQuestions) || [];

  const newQuestion: MarketingMaterialExtendedQuestion = {
    ...question,
    options: question.questionType !== 'COUNTRY' ? question.options : allCountries.map(c => ({ nameLabelId: c, value: c })),
  };
  return oldQuestions.concat(newQuestion);
};

export const mmOnChangeEvaluationQuestions = (
  value: MarketingMaterialOption,
  question: MarketingMaterialExtendedQuestion,
  existingQuestions: MarketingMaterialExtendedQuestion[],
  existingAttributes: MarketingMaterialAttribute[],
  existingSelectedInstrument: MarketingMaterialInstrument
) => {
  let newAttributesWithLicensesBasedOnValue: MarketingMaterialAttribute[] = [];
  let selectedInstrument: MarketingMaterialInstrument = existingSelectedInstrument;
  let newQuestions = cloneDeep(existingQuestions || []);
  let newAttributes = cloneDeep(existingAttributes || []);

  // sepecial use case when adding isin via modal (first time)
  if (value.isInstrumentCode) {
    const newData = updateDataAccordingToIsin(value, newAttributesWithLicensesBasedOnValue, newQuestions, question.property);
    newAttributesWithLicensesBasedOnValue = newData.attributes;
    newQuestions = newData.questions;
    question = undefined;
    selectedInstrument = convertOptionToInstrument(value);

    newAttributes = cleanAllAttributesAfterFundInfoQuestion(newAttributes, newQuestions);
  } else {

    //clear selected Instrument on changing fundQuestion or previous
    const existingProperties = newQuestions.map(_q => _q.property);
    const indexOfFundQuestion = existingProperties.indexOf('fundQuestion');
    const indexOfCurrentQuestion = existingProperties.indexOf(question.property);
    if (indexOfFundQuestion < 0 || indexOfFundQuestion > indexOfCurrentQuestion) {
      selectedInstrument = undefined;
    }

    // update questions and attributes when changing fundQuestion
    if (question.property === 'fundQuestion') {
      selectedInstrument = convertOptionToInstrument(value);
      question.options = [value];
      newQuestions = newQuestions.map(_q => (_q.property === 'fundQuestion' ? question : _q));

      newAttributes = cleanAllAttributesAfterFundInfoQuestion(newAttributes, newQuestions);
    }

    // default use cases
    newAttributesWithLicensesBasedOnValue = question?.licenses.map(l => ({
      name: question.property,
      value: value?.value,
      legalEntityId: l.legalEntity,
      licenseCountry: l.licenseCountry,
      licenseName: l.licenseName,
    }));
  }
  // when there are no new attributes this eval wont run
  // updating all attributes and questions according to new attributes
  newAttributesWithLicensesBasedOnValue.forEach(_attr => {
    const res = marketingMaterialUpdateAttributes({ marketingMaterialAttribute: _attr, marketingMaterialQuestion: question }, { attributes: newAttributes, questions: newQuestions });
    newAttributes = res.attributes;
    newQuestions = res.questions;
  });

  newAttributes = newAttributes.filter(_attr => _attr.name !== 'fundQuestion');

  return { selectedInstrument, newQuestions, newAttributes };
};

const cleanAllAttributesAfterFundInfoQuestion = (newAttributes: MarketingMaterialAttribute[], newQuestions: MarketingMaterialExtendedQuestion[]) => {
  const fundInfoIndex = newQuestions.map(_q => _q.property).indexOf('fundQuestion');
  const indexJustBeforeFundQuestion = newAttributes.map(attr => attr.name === newQuestions?.[fundInfoIndex - 1]?.property)?.lastIndexOf(true);
  if (indexJustBeforeFundQuestion > -1) {
    newAttributes = newAttributes.filter((_q, i) => i <= indexJustBeforeFundQuestion);
  }
  return newAttributes;
};
