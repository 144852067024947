import { LoadContext as LoadContextCO } from 'src/app/client-onboarding/state';
import { LoadContext as LoadContextDT } from 'src/app/data-transfer/state';
import { Label, ModelError, Option, WhiteLabelConfig } from 'src/generated/apps-api';
import { UserPreferences } from 'src/generated/apps-api/model/userPreferences';

export interface SharedStateModel {
  staticLabels: LabelsObject;
  rulesLabels: { [key: string]: LabelsObject };
  errors: SharedModelError;
  loaded: boolean;
  jumpedSteps: number[];
  currentAppInfo: AppInfo;
  whiteLabelConfig: WhiteLabelConfig;
  loggedIn: boolean;
  userPreferences: UserPreferences;
  hasExternalGrant: boolean;
  sharedResults: SharedResults;
  auditCorrelationId: string;
  // To be used whenever the error interceptor should simply propagate the error, allowing the component to handle it
  skipNextErrorHandling: boolean;
  isWhiteLabelConfigAvailable: boolean;
  currentWhiteLabelCompanyId: string;
  taxonomyVariables: TaxonomyVariable[];
  allTaxonomyVariablesWithType: { identifier: string, type: string }[];
  organizationId: string;
}

export interface AppInfo {
  titleLabelId: string;
  loadContextAction: LoadContextCO | LoadContextDT;
  introUrl: string;
}

export interface LabelsObject {
  [key: string]: Label;
}

export interface TaxonomyVariable {
  identifier: string;
  value: string;
  name: string;
  correlationId: string;
  options?: { [key: string]: string };
}

export enum RuleSet {
  CLIENT_CLASSIFICATION = 'CLIENT_CLASSIFICATION',
  CLIENT_IDENTIFICATION_NATURAL_PERSON = 'CLIENT_IDENTIFICATION_NATURAL_PERSON',
  CLIENT_IDENTIFICATION_NON_NATURAL_PERSON = 'CLIENT_IDENTIFICATION_NON_NATURAL_PERSON',
  OPERATIONAL_ACTIVITY = 'OPERATIONAL_ACTIVITY',
  TRANSFER_TERRITORIAL_SCOPING = 'TRANSFER_TERRITORIAL_SCOPING',
  DATA_CLASSIFICATION = 'DATA_CLASSIFICATION',
  DATA_TRANSFER_CONDITION = 'DATA_TRANSFER_CONDITION',
  DATA_TRANSFER = 'DATA_TRANSFER',
  SPECIAL_CONTEXT_DATA_TRANSFER = 'SPECIAL_CONTEXT_DATA_TRANSFER',
  BRAND_MARKETING = 'BRAND_MARKETING',
  CUSTODY_AND_PAYMENT = 'CUSTODY_AND_PAYMENT',
  DISCRIMINATORY_ASSET_MANAGEMENT = 'DISCRIMINATORY_ASSET_MANAGEMENT',
  INVESTMENT_ADVICE = 'INVESTMENT_ADVICE',
  LOANS_AND_CREDITS = 'LOANS_AND_CREDITS',
  RESEARCH = 'RESEARCH',
  SOCIAL_CONTACTS = 'SOCIAL_CONTACTS',
  WEBSITE = 'WEBSITE',
  DISTRIBUTION = 'DISTRIBUTION',
  WEALTH_PLANNING = 'WEALTH_PLANNING',
  WEALTH_MANAGEMENT_SERVICES = 'WEALTH_MANAGEMENT_SERVICES',
  CLIENT_ADMINISTRATION_AND_DOCUMENTS = 'CLIENT_ADMINISTRATION_AND_DOCUMENTS',
  COUNTRY_OVERVIEW_FINANCIAL_SERVICES = 'COUNTRY_OVERVIEW_FINANCIAL_SERVICES',
  COUNTRY_OVERVIEW_FINANCIAL_INSTRUMENTS = 'COUNTRY_OVERVIEW_FINANCIAL_INSTRUMENTS',
  INVESTMENT_FUNDS = 'INVESTMENT_FUNDS',
  BONDS = 'BONDS',
  EQUITIES = 'EQUITIES',
  DERIVATIVES = 'DERIVATIVES',
  STRUCTURED_PRODUCTS = 'STRUCTURED_PRODUCTS',
  E_BANKING_SERVICES = 'E_BANKING_SERVICES',
  TRUST_FICUCIARY_SERVICES = 'TRUST_FICUCIARY_SERVICES',
  INTERMEDIARIES = 'INTERMEDIARIES',
  NON_TRANSFERABLE_SECURITIES = 'NON_TRANSFERABLE_SECURITIES',
  BRAND_MARKETING_IB = 'BRAND_MARKETING_IB',
  WEBSITE_IB = 'WEBSITE_IB',
  CAPITAL_MARKET_TRANSACTION_SERVICE_IB = 'CAPITAL_MARKET_TRANSACTION_SERVICE_IB',
  FOREIGN_EXCHANGE_SERVICE_IB = 'FOREIGN_EXCHANGE_SERVICE_IB',
  TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB = 'TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB',
  TREASURY_CASH_BANKNOTE_SERVICES_IB = 'TREASURY_CASH_BANKNOTE_SERVICES_IB',
  PRECIOUS_METAL_SERVICES_IB = 'PRECIOUS_METAL_SERVICES_IB',
  RATES_SERVICES_IB = 'RATES_SERVICES_IB',
  ADVISORY_FOR_MERGERS_ACQUISITIONS_IB = 'ADVISORY_FOR_MERGERS_ACQUISITIONS_IB',
  DIGITAL_APPLICATIONS_SOLUTIONS_IB = 'DIGITAL_APPLICATIONS_SOLUTIONS_IB',
  CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB = 'CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB',
  BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB = 'BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB',
  TRADE_FINANCE_IB = 'TRADE_FINANCE_IB',
  DISTRIBUTION_STRUCTURED_PRODUCTS = 'DISTRIBUTION_STRUCTURED_PRODUCTS'
}

export enum RESULT_TYPES {
  UNDEFINED = 'undefined',
  YES = 'yes',
  NO = 'no',
}

export const Products = {
  MARKETING_MATERIAL_DOCUMENT_CHECK: 'Marketing Document Check'
};

export const ProductsIds = {
  CLIENT_ADMINISTRATION_AND_DOCUMENTS: 'Client Administration and Documents'
};

export interface SharedResults {
  staticResultLoaded?: boolean;
  createdOn?: number;
  expiryDate?: number;
  auditDataInfo?: AppInfo;
  createdBy?: string;
}

export interface CountryOption extends Option {
  countryCode?: string;
}

export interface InstrumentOption extends Option {
  providerId?: string;
}

export interface SharedModelError extends ModelError {
  stepsToGoBack?: number;
  showGoBackButton?: boolean;
}

export interface SharedResultsAuditDataInfo {
  token?: string;
  correlationId?: string;
}
