import { Selector } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import {
  CrossBorderAttribute, CrossBorderContext, CrossBorderEvaluationInformation,
  CrossBorderProduct, Option, Question,
  RuleSetInfo
} from '../../../generated/apps-api';
import {
  isFinancialServicesStaticQuestion
} from '../components/flow/static-questions/financial-services-static-questions.utils';
import { isInvestorTypeAttribute } from '../components/flow/static-questions/investor-type.state.utils';
import { CBGlobalConstants } from '../cross-border.globalConstants';
import {
  CrossBorderQuestion,
  CrossBorderStateModel,
  ExtendedProduct,
  JurisdictionCountryOverviews, SavedContext,
  Scenario
} from './cross-border.model';
import { CrossBorderState } from './cross-border.state';
import { getNextStepNumber, stepContainsQuestion } from './financial-services-steps.utils';

// required (https://github.com/ngxs/store/issues/541) otherwise when the state changes, all selectors
// will fire. The distinctUntilChanged won't work since we do a cloneDeep to fore the immutability
export class CrossBorderSelectors {

  @Selector([CrossBorderState.products])
  public static products(products: CrossBorderStateModel['products']): ExtendedProduct[] {
    return cloneDeep(products);
  }

  @Selector([CrossBorderState.generatedByFullName])
  public static generatedByFullName(generatedByFullName: CrossBorderStateModel['generatedByFullName']): string {
    return cloneDeep(generatedByFullName);
  }

  @Selector([CrossBorderState.generatedOn])
  public static generatedOn(generatedOn: CrossBorderStateModel['generatedOn']): string {
    return cloneDeep(generatedOn);
  }

  @Selector([CrossBorderState.selectedProducts])
  public static selectedProducts(selectedProducts: CrossBorderStateModel['selectedProducts']): CrossBorderProduct[] {
    return cloneDeep(selectedProducts);
  }

  @Selector([CrossBorderState.selectedProducts])
  public static selectedRuleSets(selectedProducts: CrossBorderStateModel['selectedProducts']): RuleSetInfo.RuleSetTypeEnum[] {
    return cloneDeep(selectedProducts.map(product => product.ruleset));
  }

  @Selector([CrossBorderState.scenarios])
  public static firstScenarioAttributes(scenarios: CrossBorderStateModel['scenarios']): CrossBorderAttribute[] {
    return cloneDeep(scenarios[0].attributes) || [];
  }

  @Selector([CrossBorderState.scenarios])
  public static firstScenario(scenarios: CrossBorderStateModel['scenarios']): Scenario {
    return cloneDeep(scenarios[0]);
  }

  @Selector([CrossBorderState.scenarios])
  public static scenario(scenarioIndex: number, scenarios: CrossBorderStateModel['scenarios']): Scenario {
    return cloneDeep(scenarios[scenarioIndex]);
  }

  @Selector([CrossBorderState.scenarios])
  public static firstScenarioQuestions(scenarios: CrossBorderStateModel['scenarios']): Question[] {
    return cloneDeep(scenarios[0].questions);
  }

  @Selector([CrossBorderState.firstScenarioJurisdiction])
  public static firstScenarioJurisdiction(firstScenarioJurisdiction: CrossBorderStateModel['firstScenarioJurisdiction']): string {
    return firstScenarioJurisdiction;
  }

  @Selector([CrossBorderState.instrumentsJurisdictions])
  public static instrumentsJurisdictions(instrumentsJurisdictions: CrossBorderStateModel['instrumentsJurisdictions']): string[] {
    return instrumentsJurisdictions;
  }

  @Selector([CrossBorderState.allJurisdictions])
  public static allJurisdictions(allJurisdictions: CrossBorderStateModel['allJurisdictions']): string[] {
    return cloneDeep(allJurisdictions);
  }


  @Selector([CrossBorderState.scenarios])
  public static scenarios(scenarios: CrossBorderStateModel['scenarios']): Scenario[] {
    return cloneDeep(scenarios);
  }

  @Selector([CrossBorderState.scenarios, CrossBorderState.currentStepNumber])
  public static currentStepQuestions(scenarios: CrossBorderStateModel['scenarios'], currentStep: CrossBorderStateModel['currentStep']): CrossBorderQuestion[] {
    const questions = cloneDeep(scenarios[0].questions);
    const regpropsPerStepIndex = currentStep - 1;

    return questions.filter(q => stepContainsQuestion(regpropsPerStepIndex, q));
  }

  @Selector([CrossBorderState.scenarios])
  public static firstScenarioInvestorTypeQuestions(scenarios: CrossBorderStateModel['scenarios']): CrossBorderQuestion[] {
    return cloneDeep(scenarios[0].questions.filter(q => isInvestorTypeAttribute(q.property)));
  }

  @Selector([CrossBorderState.scenarios])
  public static firstScenarioStaticQuestions(scenarios: CrossBorderStateModel['scenarios']): CrossBorderQuestion[] {
    return cloneDeep(scenarios[0].questions.filter(q => isFinancialServicesStaticQuestion(q.property)));
  }

  @Selector([CrossBorderState.scenarios])
  public static firstScenarioHasResults(scenarios: CrossBorderStateModel['scenarios']): boolean {
    return !!scenarios[0].results;
  }

  @Selector([CrossBorderState.scenarios])
  public static investorTypeStepCanProceed(scenarios: CrossBorderStateModel['scenarios']): boolean {
    const { questions, attributes } = cloneDeep(scenarios[0]);
    return questions.filter(q => isInvestorTypeAttribute(q.property)).every(q => attributes.some(attr => attr.name === q.property));
  }

  @Selector([CrossBorderState.scenarios])
  public static staticStepCanProceed(scenarios: CrossBorderStateModel['scenarios']): boolean {
    const { questions, attributes } = cloneDeep(scenarios[0]);
    return questions.filter(q => isFinancialServicesStaticQuestion(q.property)).every(q => attributes.some(attr => attr.name === q.property));
  }

  @Selector([CrossBorderState.jurisdictions])
  public static jurisdictionOptions(jurisdictions: CrossBorderStateModel['jurisdictions']): Option[] {
    return jurisdictions.filter(j => CBGlobalConstants.GLOBAL_JURISDICTION_TERM !== j.toUpperCase()).map(j => ({ nameLabelId: j, value: j }));
  }

  @Selector([CrossBorderState.scenarios])
  public static evaluationDetails(scenarios: CrossBorderStateModel['scenarios']): CrossBorderEvaluationInformation[] {
    return scenarios.filter(s => !!s?.evaluationInformation).map(s => cloneDeep(s.evaluationInformation));
  }

  @Selector([CrossBorderState.currentStepNumber])
  public static currentStepNumber(currentStep: CrossBorderStateModel['currentStep']): number {
    return currentStep;
  }

  @Selector([CrossBorderState.scenarios, CrossBorderState.currentStepNumber])
  public static nextStepNumber(scenarios: CrossBorderStateModel['scenarios'], currentStep: CrossBorderStateModel['currentStep']): number {
    return getNextStepNumber(scenarios[0].questions, currentStep, CBGlobalConstants.MAX_FINANCIAL_SERVICES_STEPS);
  }

  @Selector([CrossBorderState.countryOverviews])
  public static countryOverviews(countryOverviews: CrossBorderStateModel['countryOverviews']): JurisdictionCountryOverviews {
    return cloneDeep(countryOverviews);
  }

  @Selector([CrossBorderState.isSuperlogic])
  public static isSuperlogic(isSuperlogic: CrossBorderStateModel['isSuperlogic']): boolean {
    return isSuperlogic;
  }

  @Selector([CrossBorderState.phoneShowProactive])
  public static phoneShowProactive(phoneShowProactive: CrossBorderStateModel['phoneShowProactive']): boolean {
    return phoneShowProactive;
  }

  @Selector([CrossBorderState.phoneVisibleScenarioIndex])
  public static phoneVisibleScenarioIndex(phoneVisibleScenarioIndex: CrossBorderStateModel['phoneVisibleScenarioIndex']): number {
    return phoneVisibleScenarioIndex;
  }

  @Selector([CrossBorderState.selectedService])
  public static selectedService(selectedService: CrossBorderStateModel['selectedService']): CrossBorderProduct {
    return cloneDeep(selectedService);
  }

  @Selector([CrossBorderState.savedContext])
  public static savedContext(savedContext: CrossBorderStateModel['savedContext']): SavedContext {
    return cloneDeep(savedContext);
  }

  @Selector([CrossBorderState.savedContexts])
  public static savedContexts(savedContexts: CrossBorderStateModel['savedContexts']): CrossBorderContext[] {
    return cloneDeep(savedContexts);
  }

  @Selector([CrossBorderState.isCrossBorderIB])
  public static isCrossBorderIB(isCrossBorderIB: CrossBorderStateModel['isCrossBorderIB']): boolean {
    return cloneDeep(isCrossBorderIB);
  }
}
