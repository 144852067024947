import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { cloneDeep, isEqual, range, sortBy, uniqWith } from 'lodash-es';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { CompleteLoading, JumpedSteps, SetCurrentAppName, SetErrors, SetStaticLabels, SetTestMode, UpdateTestMode } from 'src/app/shared/state/shared.action';
import {
  CrossBorderAppContext,
  CrossBorderAppContextApiService,
  CrossBorderContext,
  CrossBorderEvaluatedRule,
  CrossBorderIbAppContextApiService,
  CrossBorderProduct,
  Option
} from 'src/generated/apps-api';
import {
  CrossBorderQuestion,
  ExtendedProduct,
  JurisdictionCountryOverviews,
  LoadContext,
  SavedContext,
  ScenarioChanged,
  SetCountryOverview,
  SetIsCrossBorderIB,
  SetSavedContext,
  SetSelectedService,
  SetSuperlogic
} from '.';
import { ToLabelPipe } from '../../shared/pipes/to-label.pipe';
import { checkIfStaticQuestion, useClientAdminDocsIBRule, useClientAdminDocsRule } from '../components/flow/static-questions/financial-services-static-questions.utils';
import { checkIfInvestorTypeAttribute } from '../components/flow/static-questions/investor-type.state.utils';
import { CBGlobalConstants } from '../cross-border.globalConstants';
import { SaveCBAudit } from './cross-border-audit.action';
import { SetInstrumentsResults } from './cross-border-instruments.action';
import {
  AddQuestion,
  DeleteSavedContext,
  DeleteScenario,
  RemoveExtraScenarios,
  RemoveSavedContext,
  SetAttribute,
  SetPhoneSelectedScenario,
  SetResults,
  SetSavedContexts,
  SetScenario,
  SetSelectedProducts,
  SetStaticResult,
  SetStepNumber,
  StartAgain,
  ToggleMarketingOrigination
} from './cross-border.action';
import { DEFAULT_STATE } from './cross-border.defaultState';
import { CrossBorderStateModel, Scenario } from './cross-border.model';

@State<CrossBorderStateModel>({
  name: 'crossBorder',
  defaults: DEFAULT_STATE
})
@Injectable()
export class CrossBorderState {
  constructor(
    private contextService: CrossBorderAppContextApiService,
    private contextIBService: CrossBorderIbAppContextApiService,
    private toLabelPipe: ToLabelPipe,
    private store: Store,
    private router: Router
  ) {}

  @Selector()
  public static fullState(state: CrossBorderStateModel): CrossBorderStateModel {
    return state;
  }

  @Selector()
  public static isCrossBorderIB(state: CrossBorderStateModel): boolean {
    return state.isCrossBorderIB;
  }

  @Selector()
  public static products(state: CrossBorderStateModel): ExtendedProduct[] {
    return state.products;
  }

  @Selector()
  public static generatedOn(state: CrossBorderStateModel): string {
    return state.generatedOn;
  }

  @Selector()
  public static generatedByFullName(state: CrossBorderStateModel): string {
    return state.generatedByFullName;
  }

  @Selector()
  public static selectedProducts(state: CrossBorderStateModel): CrossBorderProduct[] {
    return state.selectedProducts;
  }

  @Selector()
  public static firstScenarioJurisdiction(state: CrossBorderStateModel): string {
    return state.firstScenarioJurisdiction;
  }

  @Selector()
  public static scenarios(state: CrossBorderStateModel): Scenario[] {
    return state.scenarios;
  }

  @Selector()
  public static jurisdictions(state: CrossBorderStateModel): string[] {
    return state.jurisdictions;
  }

  @Selector()
  public static currentStepNumber(state: CrossBorderStateModel): number {
    return state.currentStep;
  }

  @Selector()
  public static countryOverviews(state: CrossBorderStateModel): JurisdictionCountryOverviews {
    return state.countryOverviews;
  }

  @Selector()
  public static isSuperlogic(state: CrossBorderStateModel): boolean {
    return state.isSuperlogic;
  }

  @Selector()
  public static phoneShowProactive(state: CrossBorderStateModel): boolean {
    return state.phoneShowProactive;
  }

  @Selector()
  public static phoneVisibleScenarioIndex(state: CrossBorderStateModel): number {
    return state.phoneVisibleScenarioIndex;
  }

  @Selector()
  public static selectedService(state: CrossBorderStateModel): CrossBorderProduct {
    return state.selectedService;
  }

  @Selector()
  public static allJurisdictions(state: CrossBorderStateModel): string[] {
    return state.allJurisdictions;
  }

  @Selector()
  public static instrumentsJurisdictions(state: CrossBorderStateModel): string[] {
    return state.instrumentsJurisdictions;
  }

  @Selector()
  public static savedContext(state: CrossBorderStateModel): SavedContext {
    return state.savedContext;
  }

  @Selector()
  public static savedContexts(state: CrossBorderStateModel): CrossBorderContext[] {
    return state.savedContexts;
  }

  @Action(AddQuestion)
  public addQuestion(context: StateContext<CrossBorderStateModel>, { payload }: AddQuestion) {
    const { question, scenarioIndex } = payload;
    const scenarios = cloneDeep(context.getState().scenarios);
    const scenario = scenarios[scenarioIndex || 0];

    scenario.questions = scenario.questions.concat(question);
    scenario.results = undefined;

    return context.patchState({ scenarios });
  }

  @Action(SetAttribute)
  public setAttribute(context: StateContext<CrossBorderStateModel>, { payload }: SetAttribute) {
    const { newAttribute, scenarioIndex } = payload;
    const scenarios = cloneDeep(context.getState().scenarios);
    const scenario = scenarios[scenarioIndex || 0];

    const attributeIndex = scenario.attributes.findIndex(a => a.name === newAttribute.name && isEqual(a.ruleSets, newAttribute.ruleSets));
    scenario.attributes = scenario.attributes
      .filter((_a, i) => attributeIndex === -1 || i < attributeIndex)
      .map(attr => {
        if (attr.name === newAttribute.name) {
          attr.ruleSets = attr.ruleSets.filter(ruleSet => !newAttribute.ruleSets.includes(ruleSet));
        }
        return attr;
      })
      .concat(newAttribute);

    const questionIndex = scenario.questions.findIndex(
      q =>
        q.property === newAttribute.name &&
        isEqual(
          newAttribute.ruleSets,
          q.products.map(p => p.ruleset)
        )
    );
    scenario.questions = scenario.questions.filter((_a, i) => questionIndex === -1 || i <= questionIndex);

    const { instrumentsJurisdictions, jurisdictions, isSuperlogic } = context.getState();
    const jurisdictionOptions = jurisdictions
      .filter(j => 'GLOBAL' !== j.toUpperCase())
      .map(j => ({
        nameLabelId: j,
        value: j
      }));
    const showInstrumentsHint =
      !isSuperlogic && !instrumentsJurisdictions.includes(CBGlobalConstants.GLOBAL_JURISDICTION_TERM) && jurisdictionOptions.some(option => !instrumentsJurisdictions.includes(option.value));
    const options = jurisdictionOptions.map(
      option =>
        ({
          ...option,
          hint: instrumentsJurisdictions.includes(option.value) && showInstrumentsHint ? CBGlobalConstants.WITH_INSTRUMENTS_HINT : ''
        }) as Option
    );

    checkIfInvestorTypeAttribute(newAttribute, scenario, options);
    checkIfStaticQuestion(newAttribute, scenario, options);

    return context.patchState({ scenarios });
  }

  @Action(SetSelectedService)
  public setSelectedService(context: StateContext<CrossBorderStateModel>, { payload }: SetSelectedService) {
    context.patchState({
      selectedService: payload
    });
  }

  @Action([StartAgain, UpdateTestMode])
  public startAgain(context: StateContext<CrossBorderStateModel>) {
    // although the countryOverviews is emptu on the DEFAULT_STATE the patchState or even the setState don't seem to clear it
    // this is why we have to set also the countryOverviews together with the DEFAULT_STATE
    const isCBIB = context.getState().isCrossBorderIB;
    return context.patchState({ ...DEFAULT_STATE, countryOverviews: {}, isCrossBorderIB: isCBIB });
  }

  @Action(ToggleMarketingOrigination)
  public toggleMarketingOrigination(context: StateContext<CrossBorderStateModel>) {
    const phoneShowProactive = !context.getState().phoneShowProactive;
    return context.patchState({ phoneShowProactive });
  }

  @Action(SetPhoneSelectedScenario)
  public setPhoneVisibleScenarioIndex(context: StateContext<CrossBorderStateModel>, { payload }: SetPhoneSelectedScenario) {
    return context.patchState({ phoneVisibleScenarioIndex: payload });
  }

  @Action(LoadContext)
  public loadCrossborderContext(context: StateContext<CrossBorderStateModel>) {
    let crossBorderAppContext$: Observable<CrossBorderAppContext>;
    if (context.getState().isCrossBorderIB) {
      crossBorderAppContext$ = this.contextIBService.getCrossBorderIBAppContext();
    } else {
      crossBorderAppContext$ = this.contextService.getCrossBorderAppContext();
    }
    return crossBorderAppContext$.pipe(
      first(),
      tap(res => {
        this.store.dispatch(new SetSuperlogic(res.hasSuperlogic));
        this.store.dispatch(new SetTestMode({ testMode: res.testMode }));
      }),
      switchMap(res => this.store.dispatch(new SetStaticLabels({ labels: res.staticLabels })).pipe(map(() => res))),
      tap(res => {
        if (res.configurationException) {
          this.store
            .dispatch(
              new SetErrors({
                errors: {
                  ...res.configurationException,
                  stepsToGoBack: 2
                }
              })
            )
            .subscribe(() => this.router.navigate(['/errors']).then());
        } else {
          context.patchState({
            jurisdictions: res.jurisdictions,
            instrumentsJurisdictions: res.instrumentsJurisdictions,
            allJurisdictions: res.allJurisdictions,
            products: sortBy(this.toExtendedProducts(res.products), product => product.resolvedName)
          });
          this.store.dispatch(
            new SetCurrentAppName({
              appName: res.appTermId
            })
          );
        }
        this.store.dispatch(new CompleteLoading());
      })
    );
  }

  @Action(SetStaticResult)
  public setStaticResult(context: StateContext<CrossBorderStateModel>, { payload }: SetStaticResult) {
    //same selected service on view instruments porfolio and go back to results
    const currentSelectedService = context.getState()?.selectedService;

    context.patchState({
      scenarios: payload.state,
      products: payload.products,
      countryOverviews: payload.countryOverviews,
      selectedService: currentSelectedService ? currentSelectedService : payload.selectedService,
      firstScenarioJurisdiction: payload.firstScenarioJurisdiction,
      isCrossBorderIB: payload.isCrossBorderIB
    });
    this.store.dispatch(
      new SetInstrumentsResults({
        activities: payload.instrumentsActivities,
        evaluationInformation: payload.instrumentsEvaluationInformation?.[0],
        portfolioEntries: payload.portfolioEntries,
        questions: payload.instrumentsQuestions,
        attributes: payload.instrumentsAttributes,
        instrumentsProducts: payload.instrumentsProducts,
        usePortfolio: payload.usePortfolio,
        showOverlay: payload.showOverlay
      })
    );
    return context;
  }

  @Action(SetStepNumber)
  public setStepNumber(context: StateContext<CrossBorderStateModel>, { payload }: SetStepNumber) {
    const previousStep = CrossBorderState.currentStepNumber(context.getState());
    const currentStep = payload.stepNumber;
    context.patchState({ currentStep });
    if (currentStep > previousStep + 1) {
      const jumpedSteps = range(previousStep + 1, currentStep);
      context.dispatch(new JumpedSteps({ jumpedSteps }));
    }
  }

  @Action(SetSelectedProducts)
  public setSelectedProducts(context: StateContext<CrossBorderStateModel>, { payload }: SetSelectedProducts) {
    const clientAdminAndDocumentsProduct: CrossBorderProduct = {
      ruleset: CrossBorderProduct.RulesetEnum.CLIENT_ADMINISTRATION_AND_DOCUMENTS,
      family: CrossBorderEvaluatedRule.FamilyTypeEnum.SERVICES
    };
    const clientAdminAndDocumentsIBProduct: CrossBorderProduct = {
      ruleset: CrossBorderProduct.RulesetEnum.CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB,
      family: CrossBorderEvaluatedRule.FamilyTypeEnum.SERVICES_IB
    };

    let selectedProducts = payload.products
      .concat(useClientAdminDocsRule(payload.products.map(p => p.ruleset)) ? clientAdminAndDocumentsProduct : [])
      .concat(useClientAdminDocsIBRule(payload.products.map(p => p.ruleset)) ? clientAdminAndDocumentsIBProduct : []);
    selectedProducts = uniqWith(selectedProducts, (value1, value2) => value1.family === value2.family && value1.ruleset === value2.ruleset);

    const selectedService = context.getState().products.find(p => p.ruleset === payload.products[0].ruleset);
    const scenarios = DEFAULT_STATE.scenarios;
    return context.patchState({ selectedProducts, selectedService, scenarios });
  }

  @Action(SetIsCrossBorderIB)
  public setIsCrossBorderIB(context: StateContext<CrossBorderStateModel>, { payload }: SetIsCrossBorderIB) {
    return context.patchState({ isCrossBorderIB: payload });
  }

  @Action(SetResults)
  public setResults(context: StateContext<CrossBorderStateModel>, { payload }: SetResults) {
    const { results, evaluationInformation, scenarioIndex } = payload;
    const scenarios = cloneDeep(context.getState().scenarios);
    const currentSelectedService = context.getState().selectedService?.ruleset || context.getState().selectedProducts[0];
    const selectedService = context.getState().products.find(p => p.ruleset === currentSelectedService);
    const scenario = scenarios[scenarioIndex || 0];
    scenario.results = results;
    scenario.evaluationInformation = evaluationInformation;

    context.patchState({
      scenarios,
      firstScenarioJurisdiction: payload.jurisdiction,
      selectedService
    });
  }

  @Action(SetScenario)
  public async setScenario(context: StateContext<CrossBorderStateModel>, { payload }: SetScenario) {
    const { scenario, scenarioIndex, jurisdiction, generatedOn, generatedByFullName } = payload;
    this.cleanupEmptyResults(scenario);

    const firstScenarioJurisdiction = jurisdiction ?? context.getState().firstScenarioJurisdiction;
    const scenarios = cloneDeep(context.getState().scenarios);
    const index = scenarioIndex === undefined ? scenarios.length : scenarioIndex;
    scenario.questions = this.addCountryOptionToDynamicQuestions(scenario.questions, context.getState().allJurisdictions);
    scenarios[index] = scenario;
    context.patchState({ scenarios, firstScenarioJurisdiction, generatedOn, generatedByFullName });
  }

  private addCountryOptionToDynamicQuestions(questions: CrossBorderQuestion[], jurisdictions: string[]): CrossBorderQuestion[] {
    return questions.map(question => {
      if (question.questionType === 'COUNTRY' && question.property !== 'presentFIEmployeeCountry' && question.property !== 'presentProspectClientEventCountry') {
        question.options = jurisdictions.map(jurisdiction => ({ nameLabelId: jurisdiction, value: jurisdiction }));
      }
      return question;
    });
  }

  private cleanupEmptyResults(scenario: Scenario) {
    for (const property in scenario.results) {
      if (scenario.results.hasOwnProperty(property)) {
        scenario.results[property].activities = scenario.results[property].activities.filter(a => a.properties.length !== 0);
        scenario.results[property].documents = scenario.results[property].documents.filter(a => a.properties.length !== 0);
      }
    }
  }

  @Action(DeleteScenario)
  public deleteScenario(context: StateContext<CrossBorderStateModel>, { payload }: DeleteScenario) {
    const { scenarioIndex } = payload;
    const scenarios = context.getState().scenarios.filter((_scenario, index) => index !== scenarioIndex);

    context.patchState({ scenarios });
  }

  @Action(SetCountryOverview)
  public setCountryOverview(context: StateContext<CrossBorderStateModel>, { payload }: SetCountryOverview) {
    const countryOverviews = cloneDeep(context.getState().countryOverviews);
    if (!countryOverviews[payload.jurisdiction]) {
      countryOverviews[payload.jurisdiction] = {};
    }
    if (!countryOverviews[payload.jurisdiction][payload.family]) {
      countryOverviews[payload.jurisdiction][payload.family] = {};
    }
    countryOverviews[payload.jurisdiction][payload.family][payload.countryOverview.id] = payload.countryOverview;
    return context.patchState({
      countryOverviews: cloneDeep(countryOverviews)
    });
  }

  @Action(SetSuperlogic)
  public setSuperlogic(context: StateContext<CrossBorderStateModel>, { payload }: SetSuperlogic) {
    CBGlobalConstants.isSuperlogic = payload;
    context.patchState({ isSuperlogic: payload });
  }

  @Action(SetSavedContexts)
  public setSavedContexts(context: StateContext<CrossBorderStateModel>, { payload }: SetSavedContexts) {
    const savedContexts = payload.savedContexts?.sort((c1, c2) => (c2.modifiedDate > c1.modifiedDate ? 1 : -1));
    return context.patchState({ savedContexts });
  }

  @Action(DeleteSavedContext)
  public deleteSavedContext(context: StateContext<CrossBorderStateModel>, { payload }: DeleteSavedContext) {
    const savedContexts = context.getState().savedContexts.filter(c => c.id !== payload.id);
    return context.patchState({ savedContexts });
  }

  @Action(SetSavedContext)
  public setSavedContext(context: StateContext<CrossBorderStateModel>, { payload }: SetSavedContext) {
    const currentSavedContext = context.getState().savedContext;
    const savedContext = {
      id: payload.id || currentSavedContext.id,
      name: payload.name || currentSavedContext.name,
      isSavedContext: payload?.isSavedContext ?? currentSavedContext.isSavedContext,
      modifiedDate: new Date()
    };

    context.patchState({
      savedContext
    });
  }

  @Action(RemoveSavedContext)
  public removeSavedContext(context: StateContext<CrossBorderStateModel>) {
    const savedContext = {
      isSavedContext: false
    };

    context.patchState({
      savedContext
    });
  }

  @Action(RemoveExtraScenarios)
  public removeExtraScenarios(context: StateContext<CrossBorderStateModel>) {
    const scenarios = [context.getState().scenarios[0]];
    return context.patchState({ scenarios });
  }

  @Action(ScenarioChanged)
  public scenarioChanged(context: StateContext<CrossBorderStateModel>, { scenarioIndex }: ScenarioChanged) {
    const generatedOn = context.getState().generatedOn;
    return this.store.dispatch(
      new SaveCBAudit({
        generatedOn: generatedOn,
        metadata: { scenarioIndex: scenarioIndex }
      })
    );
  }

  private toExtendedProducts(products: CrossBorderProduct[]): ExtendedProduct[] {
    return products.map(
      product =>
        ({
          ...product,
          resolvedName: this.toLabelPipe.transform(product.name),
          resolvedDescription: product.name.includes('[[') && product.name.includes(']]') ? product.name.slice(0, -2) + ':definition]]' : product.name
        }) as ExtendedProduct
    );
  }
}
